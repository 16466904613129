import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MeetingServices } from '../../../../../services';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const PastMeetings = () => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 1)));
  const [meetingRecords, setMeetingRecords] = useState([]);
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.up('xs'), { defaultMatches: true });

  useEffect(() => {
    getMeetingRecords();
  }, [startDate, endDate]);

  function getMeetingRecords () {
    const requestModel = {
      beginning: startDate,
      end: endDate,
      pageNumber: 0,
      rowCount: 100
    };
    MeetingServices.getAllMeetingRecords(requestModel).then(response => {
      if (response) {
        setMeetingRecords(response.data.result.items);
      }
    });
  }

  return (
    <Box>
      <Box marginBottom={7}>
        <Grid
          container
        >
          <Grid
            item
            md={5}
            xs={12}
          >
            <Typography
              variant="h5"
              color="text.tertiary"
              sx={{
                fontWeight: 700,
                textAlign: 'start'
              }}
            >
              {t('myPastMeeting')}
            </Typography>
          </Grid>


          <Grid
            item
            md={7}
            container
            sx={{ justifyContent: 'end' }}
          >
            <DesktopDatePicker
              maxDate={endDate}
              disableFuture
              name={'startDate'}
              label={t('meetingDateStart')}
              inputFormat="dd.MM.yyyy"
              value={startDate}
              onChange={(e) => {
                setStartDate(e);
              }}
              renderInput={(params) =>
                <TextField
                  fullWidth={isXS}
                  sx={{ float: 'right', width: { xs: '%100', md: 170 }, mr: 1, mt: { xs: 3, md: 0 } }}
                  {...params}
                  onInput={(e) => {
                    const value = e.target.value;
                    const length = value.length;
                    if (length > 10) {
                      e.target.value = value.toString().slice(0, 10);
                    }
                  }}
                />
              }
            />

            <DesktopDatePicker
              minDate={startDate}
              name={'endDate'}
              label={t('meetingDateEnd')}
              inputFormat="dd.MM.yyyy"
              value={endDate}
              onChange={(e) => {
                var newDate = new Date(e);
                newDate.setDate(newDate.getDate() + 1);
                setEndDate(newDate);
              }}
              renderInput={(params) =>
                <TextField
                  fullWidth={isXS}
                  sx={{ float: 'right', width: { xs: '%100', md: 170 }, mr: 1, mt: { xs: 3, md: 0 } }}
                  {...params}
                  onInput={(e) => {
                    const value = e.target.value;
                    const length = value.length;
                    if (length > 10) {
                      e.target.value = value.toString().slice(0, 10);
                    }
                  }}
                />
              }
            />
          </Grid>
        </Grid>

        <Grid container sx={{ marginTop: 5 }}>
          {meetingRecords.map((item, i) => {
            return (
              <Grid
                key={i}
                container
                direction={'row'}
                sx={{
                  marginTop: 2,
                  padding: 4,
                  border: '1px solid lightgray',
                  borderRadius: 3,
                  boxShadow: '0px 2px 5px -1px rgba(0,0,0,0.48)'
                }}
              >

                <Grid item md={5} xs={12}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {t('meetingName')}
                  </Typography>
                  <Typography>
                    {item.meetingName}
                  </Typography>
                </Grid>

                <Grid item md={4} xs={5}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {t('date')}
                  </Typography>
                  <Typography>
                    {new Date(item.meetingStartDate).toLocaleDateString()}
                  </Typography>
                </Grid>

                <Grid item md={2} xs={6} style={{ flex: 1 }}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {t('time')}
                  </Typography>
                  <Typography>
                    {new Date(item.meetingStartDate).toLocaleTimeString().substr(0, 5)}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={1}
                  sx={{ alignSelf: 'center', textAlign: 'center' }}
                >
                  <IconButton
                    color={'primary'}
                    disabled={!item.hasRecording}
                    onClick={() => {
                      window.open(process.env.REACT_APP_WEBRTC_BACKEND_URL + item.recordings[0].formatItem[0].url, '_blank', 'noopener,noreferrer');
                    }}>
                    <PlayCircleIcon fontSize={'large'}/>
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
          {meetingRecords.length === 0 &&
            <Grid
              container
              p={10}
              sx={{ border: '0.1px solid lightgray', borderRadius: 2 }}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography
                  align={'center'}
                >
                  {t('noData')}
                </Typography>
              </Grid>
            </Grid>
          }
        </Grid>
      </Box>
    </Box>
  );
};

export default PastMeetings;
