import React from 'react';
//import {  alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {Container, Icon, Link} from '@mui/material';
import {useTranslation} from 'react-i18next';

const Highlights = () => {
  const {t} = useTranslation();

  return (
    <Container>
      <Box
          style={{position: 'relative', zIndex: 2}}
          paddingY={{xs: 5, md: 5, lg: 10}}
      >
        <Box style={{textAlign:'center', marginBottom:'45px'}}>
          <h2 style={{color:'#424242',fontSize:'26px'}}>Neler Sağlıyoruz?</h2>
        </Box>
        <Grid container spacing={3} justifyContent={{xs: 'center', md: 'space-between'}}>
          {[
            {
              title: t('secureConnection'),
              subtitle: t('secureConnectionText'),
              link: '/features#section1',
              icon: (
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65" viewBox="0 0 64 65" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.2117 15.1041C14.7743 14.5329 15.5374 14.2121 16.333 14.2121H46.333C47.1287 14.2121 47.8917 14.5329 48.4543 15.1041C49.0169 15.6752 49.333 16.4498 49.333 17.2575V28.4301C49.333 45.4629 35.0874 51.1 32.2939 52.0427C31.6726 52.2686 30.9934 52.2686 30.3721 52.0427C27.5786 51.1 13.333 45.4629 13.333 28.4301V17.2575C13.333 16.4498 13.6491 15.6752 14.2117 15.1041ZM46.333 17.2575L16.333 17.2575L16.333 28.4301C16.333 43.3259 28.745 48.2877 31.333 49.1577C33.921 48.2877 46.333 43.3259 46.333 28.4301V17.2575Z" fill="#53B2FF"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M40.6683 25.3425C41.2402 25.9509 41.2178 26.9148 40.6184 27.4953L29.6122 38.154C29.0323 38.7156 28.1199 38.7152 27.5404 38.1531L22.0467 32.8238C21.4478 32.2428 21.4262 31.2789 21.9986 30.6709C22.5709 30.0629 23.5204 30.0411 24.1193 30.6221L28.5776 34.947L38.5476 25.2918C39.147 24.7113 40.0965 24.734 40.6683 25.3425Z" fill="#53B2FF"/>
                  </svg>
              ),
            },
            {
              title: t('flexibleUsage'),
              subtitle: t('flexibleUsageText'),
              link: '/features#section2',
              icon: (
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <path d="M23.794 27.9032H15.3991C15.0281 27.9032 14.6722 28.0497 14.4098 28.3107C14.1474 28.5716 14 28.9255 14 29.2945V44.5988C14 44.9678 14.1474 45.3217 14.4098 45.5826C14.6722 45.8435 15.0281 45.9901 15.3991 45.9901H23.794M23.794 27.9032V45.9901M23.794 27.9032L30.7898 13.9901C32.2741 13.9901 33.6976 14.5764 34.7472 15.6201C35.7968 16.6638 36.3864 18.0793 36.3864 19.5553V23.7292H47.2123C47.6091 23.7283 48.0015 23.8117 48.3631 23.974C48.7248 24.1364 49.0473 24.3738 49.3091 24.6703C49.5708 24.9668 49.7657 25.3156 49.8807 25.6932C49.9957 26.0709 50.028 26.4686 49.9756 26.8597L47.8769 43.5553C47.792 44.2256 47.4648 44.8422 46.9564 45.2902C46.4479 45.7382 45.7929 45.987 45.1136 45.9901H23.794" stroke="#53B2FF" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
              ),
            },
            {
              title: t('easyCommunication'),
              subtitle: t('easyCommunicationText'),
              link: '/features#section3',
              icon: (
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M23.6916 34.7632C21.3975 34.7632 19.5378 36.623 19.5378 38.9171C19.5378 41.2112 21.3975 43.0709 23.6916 43.0709C25.9857 43.0709 27.8454 41.2112 27.8454 38.9171C27.8454 36.623 25.9857 34.7632 23.6916 34.7632ZM16.7686 38.9171C16.7686 35.0936 19.8681 31.994 23.6916 31.994C27.5151 31.994 30.6146 35.0936 30.6146 38.9171C30.6146 42.7406 27.5151 45.8401 23.6916 45.8401C19.8681 45.8401 16.7686 42.7406 16.7686 38.9171Z" fill="#53B2FF"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M23.6923 45.84C22.2951 45.84 20.9171 46.1653 19.6674 46.7902C18.4177 47.415 17.3307 48.3222 16.4924 49.44C16.0336 50.0518 15.1657 50.1757 14.5539 49.7169C13.9422 49.2581 13.8182 48.3902 14.277 47.7785C15.3733 46.3168 16.7948 45.1304 18.429 44.3133C20.0632 43.4962 21.8652 43.0708 23.6923 43.0708C25.5195 43.0708 27.3215 43.4962 28.9557 44.3133C30.5899 45.1304 32.0114 46.3168 33.1077 47.7785C33.5665 48.3902 33.4425 49.2581 32.8308 49.7169C32.219 50.1757 31.3511 50.0518 30.8923 49.44C30.054 48.3222 28.9669 47.415 27.7173 46.7902C26.4676 46.1653 25.0895 45.84 23.6923 45.84Z" fill="#53B2FF"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M23.6916 16.7632C21.3975 16.7632 19.5378 18.623 19.5378 20.9171C19.5378 23.2112 21.3975 25.0709 23.6916 25.0709C25.9857 25.0709 27.8454 23.2112 27.8454 20.9171C27.8454 18.623 25.9857 16.7632 23.6916 16.7632ZM16.7686 20.9171C16.7686 17.0936 19.8681 13.994 23.6916 13.994C27.5151 13.994 30.6146 17.0936 30.6146 20.9171C30.6146 24.7406 27.5151 27.8401 23.6916 27.8401C19.8681 27.8401 16.7686 24.7406 16.7686 20.9171Z" fill="#53B2FF"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M23.6923 27.84C22.2951 27.84 20.9171 28.1653 19.6674 28.7902C18.4177 29.415 17.3307 30.3222 16.4924 31.44C16.0336 32.0518 15.1657 32.1757 14.5539 31.7169C13.9422 31.2581 13.8182 30.3902 14.277 29.7785C15.3733 28.3168 16.7948 27.1304 18.429 26.3133C20.0632 25.4962 21.8652 25.0708 23.6923 25.0708C25.5195 25.0708 27.3215 25.4962 28.9557 26.3133C30.5899 27.1304 32.0114 28.3168 33.1077 29.7785C33.5665 30.3902 33.4425 31.2581 32.8308 31.7169C32.219 32.1757 31.3511 32.0518 30.8923 31.44C30.054 30.3222 28.9669 29.415 27.7173 28.7902C26.4676 28.1653 25.0895 27.84 23.6923 27.84Z" fill="#53B2FF"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M40.3078 34.7632C38.0137 34.7632 36.154 36.623 36.154 38.9171C36.154 41.2112 38.0137 43.0709 40.3078 43.0709C42.6019 43.0709 44.4616 41.2112 44.4616 38.9171C44.4616 36.623 42.6019 34.7632 40.3078 34.7632ZM33.3848 38.9171C33.3848 35.0936 36.4843 31.994 40.3078 31.994C44.1313 31.994 47.2309 35.0936 47.2309 38.9171C47.2309 42.7406 44.1313 45.8401 40.3078 45.8401C36.4843 45.8401 33.3848 42.7406 33.3848 38.9171Z" fill="#53B2FF"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M40.3076 45.84C38.9104 45.84 37.5324 46.1653 36.2827 46.7902C35.033 47.415 33.9459 48.3222 33.1076 49.44C32.6488 50.0518 31.7809 50.1757 31.1692 49.7169C30.5574 49.2581 30.4334 48.3902 30.8922 47.7785C31.9885 46.3168 33.41 45.1304 35.0442 44.3133C36.6785 43.4962 38.4805 43.0708 40.3076 43.0708C42.1347 43.0708 43.9367 43.4962 45.5709 44.3133C47.2051 45.1304 48.6267 46.3168 49.7229 47.7785C50.1817 48.3902 50.0578 49.2581 49.446 49.7169C48.8342 50.1757 47.9664 50.0518 47.5075 49.44C46.6692 48.3222 45.5822 47.415 44.3325 46.7902C43.0828 46.1653 41.7048 45.84 40.3076 45.84Z" fill="#53B2FF"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M40.3078 16.7632C38.0137 16.7632 36.154 18.623 36.154 20.9171C36.154 23.2112 38.0137 25.0709 40.3078 25.0709C42.6019 25.0709 44.4616 23.2112 44.4616 20.9171C44.4616 18.623 42.6019 16.7632 40.3078 16.7632ZM33.3848 20.9171C33.3848 17.0936 36.4843 13.994 40.3078 13.994C44.1313 13.994 47.2309 17.0936 47.2309 20.9171C47.2309 24.7406 44.1313 27.8401 40.3078 27.8401C36.4843 27.8401 33.3848 24.7406 33.3848 20.9171Z" fill="#53B2FF"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M40.3076 27.84C38.9104 27.84 37.5324 28.1653 36.2827 28.7902C35.033 29.415 33.9459 30.3222 33.1076 31.44C32.6488 32.0518 31.7809 32.1757 31.1692 31.7169C30.5574 31.2581 30.4334 30.3902 30.8922 29.7785C31.9885 28.3168 33.41 27.1304 35.0442 26.3133C36.6785 25.4962 38.4805 25.0708 40.3076 25.0708C42.1347 25.0708 43.9367 25.4962 45.5709 26.3133C47.2051 27.1304 48.6267 28.3168 49.7229 29.7785C50.1817 30.3902 50.0578 31.2581 49.446 31.7169C48.8342 32.1757 47.9664 32.0518 47.5075 31.44C46.6692 30.3222 45.5822 29.415 44.3325 28.7902C43.0828 28.1653 41.7048 27.84 40.3076 27.84Z" fill="#53B2FF"/>
                  </svg>
              ),
            },
          ].map((item, i) => (
              <Grid item xs={10} md={4} key={i} justifyContent={'center'}>
                <Box
                    height={232}
                    position={'relative'}
                    sx={{
                      transition: 'all .3s ease-in-out',
                      backgroundColor: '#fff',
                      border:'1px solid #AED0EC',
                      borderRadius:'30px',
                      '& *':{color:'#4A4B4B', transition: 'all .3s ease-in-out'},
                      '&:after': {content: '""',
                            backgroundColor: '#53B2FF',
                            left:0,right:0,top:0,bottom:0,
                            position:'absolute',
                            opacity:0,
                            borderRadius: '20px',
                            transition:'all 1s ease-in-out'},
                        '&:hover:after': {opacity: 1},
                        '&:hover *':{color:'#fff'}
                    }}>
                  <Box
                      display={'flex'}
                      component={Link}
                      flexDirection={'column'}
                      alignItems={'flex-start'}
                      paddingTop={4}
                      paddingLeft={'20px'}
                      paddingRight={'20px'}
                      href={item.link}
                      zIndex={2}
                      position={'relative'}
                      sx={{textDecoration: 'none'}}
                  >
                    <Box display={'flex'} alignItems={'center'}>
                      <Box
                          component={Icon}
                          width={64}
                          height={64}
                          bgcolor={'#F0F5FF'}
                          borderRadius={'12px'}
                          variant='square'
                      >
                        {item.icon}
                      </Box>
                      <Box flex={1} maxWidth={'130px'} paddingLeft={'15px'}>
                        <Typography
                            variant={'h6'}
                            gutterBottom
                            sx={{fontWeight: 600, fontSize:'18px !important'}}
                            align={'left'}
                        >
                          {item.title}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography align={'left'} marginTop={'15px'} style={{fontSize:'15px'}}>
                      {item.subtitle}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Highlights;
