import Cookies from 'universal-cookie';

const cookies = new Cookies();

function g() {
  let json = cookies.get('token');
  if (json) {
    return json;
  }
  return null;
}

export default {
  getToken: g
};
