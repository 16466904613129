import store from '../redux/store';
import {PricingServices, UserServices} from 'services/index';
import {
  changeAuthentication,
  setApplicationInformationIsLoaded,
  setCurrentProduct,
  setPrivilegeNames,
  setPrivileges,
  setUser
} from '../redux/actions';

export const handleAfterLoginTokenWorks = (token, setCookie) => {
  setCookie('token', token, {path: '/', maxAge: 604800 * 4});
};

export const handleAfterLoginStoreWorks = () => {

  UserServices.getUserInformation()
    .then((response) => {
      if (response) {
        store.dispatch(setPrivileges(response.data.privileges));
        store.dispatch(changeAuthentication(!!response.data.user));
        store.dispatch(setUser(response.data.user));
        store.dispatch(setApplicationInformationIsLoaded(true));
        store.dispatch(setPrivilegeNames());
      }
    })
    .then(() => {
      PricingServices.getCurrentProduct().then((response) => {
        store.dispatch(setCurrentProduct(response?.data));
      });
    });
};

