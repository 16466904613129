import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

const TooltipButton = ({ showTooltipWhenDisabled, tooltipText, ...buttonProps }) => {

  if (showTooltipWhenDisabled && buttonProps.disabled) {
    return (
      <Tooltip title={tooltipText}>
        <span>
          <Button {...buttonProps} />
        </span>
      </Tooltip>
    );
  }
  else if (!showTooltipWhenDisabled && tooltipText) {
    return (
      <Tooltip title={tooltipText}>
        <span>
          <Button {...buttonProps} />
        </span>
      </Tooltip>
    );
  }
  else {
    return (
      <Button {...buttonProps} />
    );
  }
};

TooltipButton.propTypes = {
  tooltipText: PropTypes.string,
  buttonProps: PropTypes.object,
  showTooltipWhenDisabled: PropTypes.bool.isRequired
};

export default TooltipButton;
