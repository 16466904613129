import {Box, Icon, ListItem, ListItemText} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import handleSignOut from '../../../../../utils/signout';

const CustomListItem = ({to, title, isSelected, isExit, icon}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [cookie, setCookie, removeCookie] = useCookies(['loginToken']);
  // const hubConnection = useSelector((state) => state.main.hubConnection);

  return !isExit ? (
    <ListItem component={Link} to={to} sx={isSelected ? {
      backgroundColor: '#F0F5FF',
      '&:hover':{
        backgroundColor: '#F0F5FF',
      },
      padding:'5px 10px',
      borderRadius:'4px',
      border:'none',
      fontSize:'11px',
      color: '#424242',
    } : {color: '#424242',padding:'5px 10px',
      borderRadius:'4px', '&:hover':{
        backgroundColor: 'transparent',
      } }} button>
      <Box
        component={Icon}
        width={30}
        height={30}
        margin={1}
        color={'#53B2FF'}
        variant="square"
      >
        {icon}
      </Box>
      <ListItemText primary={title}/>
    </ListItem>
  ) : (
    <ListItem component={Link} to={'/'} onClick={() => {
      // hubConnection.stop();
      handleSignOut(dispatch, removeCookie, history);
    }} sx={{  color: '#424242',
      borderRadius:'4px',
      '&:hover':{
        backgroundColor: 'transparent',
      },
      padding:'5px 10px'}} button>
      <Box
        component={Icon}
        width={30}
        height={30}
        margin={1}
        color={'#53B2FF'}
        bgcolor={'none'}
        variant="square"
      >
        {icon}
      </Box>
      <ListItemText primary={title}/>
    </ListItem>
  );
};

CustomListItem.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  isSelected: PropTypes.bool,
  isExit: PropTypes.bool,
  icon: PropTypes.object
};

export default CustomListItem;
