import React, { useEffect, useState } from 'react';
import CreateIcon from '@mui/icons-material/Create';
import MenuIcon from '@mui/icons-material/Menu';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PropTypes from 'prop-types';
import { Autocomplete } from '@mui/material';
import { MeetingServices, UserServices } from '../../../../../../services';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import * as yup from 'yup';
import moment from 'moment';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import TimeTextField from '../../../../../../common/TimeTextField';

yup.addMethod(yup.date, 'format', function(formats, parseStrict) {
  return this.transform(function(value, originalValue) {
    value = moment(originalValue, formats, parseStrict);
    return value.isValid() ? value.toDate() : new Date('');
  });
});

const filter = createFilterOptions();

const PlanMeetingEditForm = ({ setVisible, selectedMeeting, getMeetings }) => {
  const { t } = useTranslation();
  const [companyUsers, setCompanyUsers] = useState([]);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [endDate, setEndDate] = useState('');
  const initialValues = selectedMeeting;
  const validationSchema = yup.object({
    title: yup
      .string(t('requiredMeetingTitle'))
      .min(2)
      .trim()
      .required(t('requiredMeetingTitle')),
    meetingUsers: yup
      .mixed()
      .test('test-cert1', t('requiredAddParticipant'), (value) => {
        return Array.isArray(value) && value.length > 0;
      }),
    start: yup
      .date(t('validDate'))
      .min(new Date(), t('validDate'))
      .nullable(false),
    end: yup
      .date(t('validDate'))
      .min(moment(endDate ? endDate : new Date()), t('validDate'))
      .nullable(false),
  });

  useEffect(() => {
    let promise = UserServices.getAllCompanyUsers();
    promise.then((response) => {
      if (response && response.data && response.data.length > 0) {
        setCompanyUsers(response.data);
        formik.setValues(initialValues);
      }
    });
  }, []);

  const onSubmit = (values) => {
    setButtonIsLoading(true);

    let model = {
      id: values.id,
      meetingId: values.meetingId,
      name: values.title,
      description: values.body,
      startingDate: moment(values.start)
        .local()
        .format(),
      endDate: moment(values.end)
        .local()
        .format(),
      userIds: values.meetingUsers,
    };

    var promise = MeetingServices.createMeeting(model);

    promise.then((response) => {
      setButtonIsLoading(false);
      setVisible(false);
      getMeetings();
      if (response && response.data) {
        let timerInterval;
        Swal.fire({
          icon: 'success',
          title: t('success'),
          timer: 4000,
          timerProgressBar: true,
          showCloseButton: false,
          showConfirmButton: true,
          showDenyButton: false,
          confirmButtonText: t('ok'),
          text: t('successPlanMeeting'),
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then(() => null);
      } else {
        let timerInterval;
        Swal.fire({
          icon: 'warning',
          title: t('error'),
          timer: 4000,
          timerProgressBar: true,
          showCloseButton: false,
          showConfirmButton: true,
          showDenyButton: false,
          confirmButtonText: t('ok'),
          text: t('errorPlanMeeting'),
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then(() => null);
      }
    });
  };

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    setEndDate(formik?.values?.endDate);
  }, [formik.values.endDate]);

  const ValidateEmail = (email) => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email.match(validRegex)) {
      alert(t('validEmail'));
      return false;
    }
    return true;
  };

  return (
    <Box sx={{ overFlow: 'scroll' }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid spacing={1} container width={'100%'} paddingTop={2}>
          <Grid item xs={12} paddingBottom={2}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <CreateIcon sx={{ color: '#9c9c9c', mr: 1, my: 2 }} />
              <TextField
                placeholder={t('meetingTitleRequired')}
                variant="outlined"
                name={'title'}
                type={'text'}
                fullWidth
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                FormHelperTextProps={{
                  style: {
                    backgroundColor: '#EFEFEF',
                    padding: 0,
                    margin: 0,
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} paddingBottom={2}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <PersonAddAltIcon sx={{ color: '#9c9c9c', mr: 1, my: 3 }} />
              <Autocomplete
                freeSolo
                multiple
                fullWidth
                clearOnBlur
                handleHomeEndKeys
                options={companyUsers.filter(
                  (item) =>
                    !formik.values.meetingUsers
                      .map((uItem) => uItem.email)
                      .includes(item.email),
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    name={'meetingUsers'}
                    placeholder={t('addParticipants')}
                    variant="outlined"
                    error={
                      formik.touched.meetingUsers &&
                      Boolean(formik.errors.meetingUsers)
                    }
                    helperText={
                      formik.touched.meetingUsers && formik.errors.meetingUsers
                    }
                  />
                )}
                value={formik.values.meetingUsers}
                placeholder={t('addParticipants')}
                onKeyDown={(e) => {
                  if (e.keyCode == 13) {
                    return null;
                  }
                }}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.fullName;
                }}
                onChange={(e, values) => {
                  if (values.length > 0) {
                    let item = values[values.length - 1];
                    if (typeof item === 'object') {
                      if (values.length > 0) {
                        let isNewItem = item.email
                          ? item.email.includes('Ekle ')
                          : false;
                        if (isNewItem) {
                          item.email = isNewItem
                            ? item.email.split('Ekle ')[1]
                            : item.email;
                          item.fullName = isNewItem
                            ? item.fullName.split('Ekle ')[1]
                            : item.fullName;
                        }
                        if (ValidateEmail(item.email ? item.email : item)) {
                          isNewItem ? companyUsers.push(item) : null;
                          formik.setFieldValue('meetingUsers', values);
                        }
                      } else {
                        formik.setFieldValue('meetingUsers', values);
                      }
                    } else {
                      if (ValidateEmail(item)) {
                        let newUser = {
                          email: item,
                          fullName: item,
                          id: formik.values.meetingUsers.length + 1,
                        };
                        let currentUsers = [...formik.values.meetingUsers];
                        currentUsers.push(newUser);
                        companyUsers.push(newUser);
                        formik.setFieldValue('meetingUsers', currentUsers);
                      }
                    }
                  } else {
                    formik.setFieldValue('meetingUsers', values);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option.email,
                  );

                  if (inputValue !== '' && !isExisting) {
                    let newEmail = {
                      email: 'Ekle ' + inputValue,
                      fullName: 'Ekle ' + inputValue,
                      id: filtered.length + 1,
                    };
                    filtered.push(newEmail);
                  }
                  return filtered;
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} paddingBottom={2} display={'flex'}>
            <QueryBuilderIcon sx={{ color: '#9c9c9c', mr: 1, my: 2 }} />

            <DesktopDatePicker
              disablePast
              name={'start'}
              inputFormat="dd/MM/yyyy"
              value={formik.values.start}
              onChange={(value) => {
                formik.setFieldValue('start', moment(value));
                formik.setFieldValue('end', moment(value));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={formik.handleChange}
                  fullWidth
                />
              )}
            />

            <TimeTextField
              id="time"
              type="time"
              name={'start'}
              value={moment(formik.values.start).format('HH:mm')}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 1800,
              }}
              onChange={(value) => {
                let start = formik.values.start;
                let hour = value.split(':')[0];
                let minutes = value.split(':')[1];
                let newDate = moment(start)
                  .set('hours', hour)
                  .set('minutes', minutes);
                if (moment() < newDate) {
                  formik.setFieldValue('start', newDate);
                  formik.setFieldValue(
                    'end',
                    moment(newDate).add(30, 'minutes'),
                  );
                }
              }}
              sx={{ width: 285, marginLeft: 1 }}
            />
          </Grid>
          <Grid item xs={12} md={6} paddingBottom={2} display={'flex'}>
            <ArrowRightAltIcon
              sx={{
                marginTop: 2,
                marginLeft: 0,
                width: 30,
                marginRight: { xs: 0, md: 1 },
              }}
            />
            <DesktopDatePicker
              minDate={new Date(formik.values.start)}
              disablePast
              name={'end'}
              inputFormat="dd/MM/yyyy"
              value={formik.values.end}
              onChange={(value) => {
                let endDate = formik.values.end;
                let startingDate = formik.values.start;

                if (endDate > startingDate) {
                  let newDate = moment(value)
                    .set('hours', moment(startingDate).hour())
                    .set('minutes', moment(startingDate).minutes() + 30);
                  formik.setFieldValue('end', newDate);
                } else {
                  formik.setFieldValue('end', moment(value));
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={formik.handleChange}
                  fullWidth
                />
              )}
              sx={{ marginLeft: { xs: 3, md: 1 } }}
            />

            <TimeTextField
              id="time"
              type="time"
              name={'end'}
              value={moment(formik.values.end).format('HH:mm')}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 0, // 5 min
              }}
              onChange={(value) => {
                let end = formik.values.end;
                let start = formik.values.start;
                let hour = value.split(':')[0];
                let minutes = value.split(':')[1];
                let newDate = moment(end)
                  .set('hours', hour)
                  .set('minutes', minutes);
                if (newDate > start) {
                  formik.setFieldValue('end', newDate);
                }
              }}
              sx={{ width: 285, marginLeft: 1 }}
            />
          </Grid>
          <Grid item xs={12} paddingBottom={2}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <MenuIcon sx={{ color: '#9c9c9c', mr: 1, my: 5 }} />
              <TextField
                placeholder={t('addDescription')}
                name={'body'}
                type={'textArea'}
                fullWidth
                value={formik.values.body || ''}
                onChange={formik.handleChange}
                multiline
                rows={3}
              />
            </Box>
          </Grid>
          <Grid item container xs={12}>
            <Box display="flex" justifyContent={'end'} width={'100%'}>
              <LoadingButton
                loading={buttonIsLoading}
                size={'large'}
                variant={'contained'}
                type={'submit'}
                disabled={buttonIsLoading || !formik.isValid}
                sx={{ width: 150, padding:'14px', background:'linear-gradient(to right, #0052D4, #4364F7, #6FB1FC) !important' }}
              >
                {t('save')}
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

PlanMeetingEditForm.propTypes = {
  setVisible: PropTypes.func,
  getMeetings: PropTypes.func,
  selectedMeeting: PropTypes.object,
};

export default PlanMeetingEditForm;
