import React, {useEffect} from 'react';
import {Box, Typography} from '@mui/material';
import callUser from '../svg/icons/call-user.svg';
import callClose from '../svg/icons/call-close.svg';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

const CallUnAnsweredNotification = ({setShowModal}) => {
  const {t, i18n} = useTranslation();
  const outGoingCallUserName = useSelector((state) => state.main.outGoingCallUserName);

  useEffect(() => {
    setTimeout(() => {
      setShowModal(false);
    }, 5000);
  }, [setShowModal]);

  return (
    <div>
      <Box width={{md: '324px', xs: '284px'}} style={{
        background: 'rgba(143,143,143,0.9)',
        width: '324px',
        height: '250px',
        right: '20px',
        bottom: '21px',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '11px',
        zIndex: 2,
        padding: '20px',
      }}>
        <Box>
          <Box mb={2}>
            <Typography component={'span'} fontSize={'15px'} style={{color: '#fff'}}>
              {outGoingCallUserName} {t('callBusy')}
            </Typography>
          </Box>
          <Box textAlign={'center'} position={'relative'} height={80} style={{paddingTop: '15px'}}>
            <img src={callUser} width={60} height={60}/>
          </Box>
          <Box textAlign={'center'} marginTop={3} aria-disabled={true}>
            <a
              style={{marginLeft: '11px', display: 'inline-block', cursor: 'pointer'}}
              onClick={() => setShowModal(false)}>
              <img src={callClose} width={48} height={48}/>
            </a>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

CallUnAnsweredNotification.propTypes = {
  setShowModal: PropTypes.func,
  outGoingCallUserName: PropTypes.string,
};

export default CallUnAnsweredNotification;
