import React, {useEffect, useState} from 'react';
import {Hero, Highlights} from './components';
import HeroSlider from './components/HeroSlider/HeroSlider';
import {Box, Button, Grid, Typography} from '@mui/material';
import {AccountServices} from '../../../services';
import Swal from 'sweetalert2';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import AppSection from './components/AppSection';
import PricingTable from '../../supportingPages/Pricing/components/PricingTable';
import {Container} from '@mui/material';

const Home = () => {
    const {t,i18n} = useTranslation();
    const history = useHistory();
    const query = new URLSearchParams(location.search);
    const notme = query.get('notMe');
    const accountverification = query.get('v');
    const paymentStatus = query.get('paymentStatus');
    const statusMessage = query.get('statusMessage');
    const [licenseType,setLicenseType] = useState('monthly');

    const priceTypeChange = (licenseType) => {
        setLicenseType(licenseType);
    };

    let accontVerificationUrl = '/account/company-approval?v=' + accountverification + '' + (notme !== null ? '&notMe=' + notme : '');

    useEffect(() => {
        if (accountverification) {
            let promise = AccountServices.verifyAccount(accontVerificationUrl);
            promise.then((res) => {
                if (!res && notme == false) {
                    let timerInterval;
                    Swal.fire({
                        icon: 'success',
                        title: t('success'),
                        timer: 4000,
                        timerProgressBar: true,
                        showCloseButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        text: 'Kurumsal Hesaba Geçişiniz Onaylanmıştır.',
                        willClose: () => {
                            clearInterval(timerInterval);
                        }
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.timer) {
                            history.push('/login');
                        }
                    });
                }
            }).catch((e) => {
                console.log(e);
            });
        }
        if (paymentStatus) {
            if (paymentStatus === 'failure') {
                Swal.fire({
                    icon: 'error',
                    title: t('error'),
                    text: statusMessage,
                    confirmButtonText: t('ok')
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.history.replaceState(null, null, '/');
                    }
                });
            } else if (paymentStatus === 'success') {
                window.history.replaceState(null, null, '/');
                Swal.fire({
                    icon: 'success',
                    title: t('success'),
                    text: t('packageStatusOK'),
                    confirmButtonText: t('ok')
                }).then(() => null);
            }
        }
    }, []);

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, []);

    return (
        <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
            <div>
                <HeroSlider/>
            </div>
            <div>
                <Hero/>
            </div>
            <AppSection/>
            <Box component={'div'} sx={{
                background: '#fff',
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
            }}>
                <Highlights/>
            </Box>

            <Box sx={{backgroundColor:'#424242', height:'1px', width:'100%', maxWidth:'1400px', margin:'40px auto 100px'}}></Box>
            <Box component={'div'} sx={{
                background: '#fff',
                flex: 1,
                display: 'flex',
                marginBottom:'100px',
                justifyContent: 'center',
                position: 'relative',
            }}>
                <Container>
                    <Box sx={{margin: '0 10px 0 10px'}}>
                        <Typography variant="h6" sx={{ color: '#3E3E3E', fontWeight: 700}}>
                            {t('packageTitle')}
                        </Typography>
                        <Box marginTop={2}>
                            <Grid container>
                                <Grid md={8} mt={2}>
                                    <Typography variant="body1" sx={{ color: '#3E3E3E', fontWeight: 500}}>
                                        {t('packageText')}
                                    </Typography>
                                </Grid>
                                <Grid md={4} xs={12} mt={2} textAlign={{md:i18n.language === 'ar'?'left':'right',xs:'center'}}>
                                    <Button
                                        onClick={() => priceTypeChange('monthly')}
                                        variant='contained'
                                        color={licenseType === 'monthly'?'primary':'secondary'}
                                        sx={{width: 101, height: 42, marginRight:'10px', color: licenseType === 'monthly'?'#fff':'#53B2FF'}}
                                    >
                                        {t('packetsMonthly')}
                                    </Button>
                                    <Button
                                        onClick={() => priceTypeChange('annual')}
                                        variant='contained'
                                        color={licenseType === 'annual'?'primary':'secondary'}
                                        sx={{width: 101, height: 42, marginRight:i18n.language === 'ar'?'10px':0, color: licenseType === 'annual'?'#fff':'#53B2FF'}}
                                    >
                                        {t('packetsYearly')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <PricingTable licenseType={licenseType} minimal={true}/>
                </Container>
            </Box>
        </div>
    );
};

export default Home;
