export const languageFormat = (language) =>{
  switch (language) {
    case 'tr':
      return 'tr-TR';
    case 'ar':
      return 'ar-AR';
    case 'ru':
      return 'ru-RU';
    case 'en':
      return 'en-US';
    case 'fr':
      return 'fr-FR';
  }
};

export default {
  languageFormat
};