import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import MeetingForm from './components/MeetingForm';
import {useTranslation} from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {xs: '95%', md: 800},
	maxHeight: {xs: '85%', md: 1000},
	bgcolor: 'background.paper',
	border: '0px solid #000',
	borderRadius: '10px',
	boxShadow: 24,
	p: 2,
	overflow: 'auto',
	'&::-webkit-scrollbar': {
		width: '0px'
	}
};

const CreateMeetingModal = ({visible, setVisible, userCurrentProduct, getMeetings}) => {
	const {t} = useTranslation();
	const [expanded, setExpanded] = useState(true);
	
	let disablePlanMeeting = () => {
		
		if (userCurrentProduct.title !== 'pricingDefault') {
			return !userCurrentProduct.createMeetingForLater;
		} else {
			return true;
		}
	};
	
	return (
		<Modal
			onClose={() => setVisible(false)}
			open={visible}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box style={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				maxWidth: '551px',
				width: '100%',
				minHeight: '331px',
				backgroundColor: '#fff',
				border: '0px solid #000',
				borderRadius: '15px',
				boxShadow: 24,
				padding: '60px 20px 20px 20px',
				paddingBottom: 5,
				overflow: 'auto',
				'&::-webkit-scrollbar': {
					width: '0px'
				}
			}}>
				
				<a style={{
					backgroundColor: '#F0F5FF',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '100%',
					width: '34px',
					height: '34px',
					position: 'absolute', right: '20px', top: '20px'
				}} onClick={() => {
					setVisible(false);
				}}>
					<CloseRoundedIcon/>
				</a>
				
				<Box style={{width: '440px', margin: '0 auto'}}>
					<MeetingForm
						getMeetings={getMeetings}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

CreateMeetingModal.propTypes = {
	visible: PropTypes.bool,
	setVisible: PropTypes.func,
	getMeetings: PropTypes.func,
	userCurrentProduct: PropTypes.object
};

export default CreateMeetingModal;
