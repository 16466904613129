import {Box, Button, TextField, Typography} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import React from 'react';
import {useSelector} from 'react-redux';
import storage from '../../../../utils/storage';
import store from '../../../../redux/store';
import {setPackages, setSelectedPackage} from '../../../../redux/actions';
import {useTranslation} from 'react-i18next';
import CurrencySymbol from '../../../../common/CurrencySymbol';
import StandartIcon from 'svg/icons/standart.svg';
import BusinessIcon from 'svg/icons/business.svg';
import EnterpriseIcon from 'svg/icons/enterprise.svg';
import CheckIcon from '@mui/icons-material/Check';
import {convertDigitIn} from '../../../../utils/text';

const PricingCard = ({licenseType, item,minimal}) => {
  const isAuthenticated = useSelector((state) => state.main.isAuthenticated);
  const packages = useSelector((state) => state.main.packages);
  const [pricing, setPricing] = React.useState({standart: 1, business: 11});
  const history = useHistory();
  const {t,i18n} = useTranslation();

  const onStandartUserCountChange = (value) => {
    const packageItem = packages.find((item) => item.id === 'standart');
    const data = {
      ...packageItem,
      userCount: parseInt(value),
    };
    const price = {standart: parseInt(value), business: pricing.business};
    setPricing(price);
    const packageList = packages.map((packageItem) => {
      if (packageItem.id === data.id) {
        return data;
      } else {
        return packageItem;
      }
    });
    store.dispatch(setSelectedPackage(data));
    store.dispatch(setPackages(packageList));
  };

  const onBusinessUserCountChange = (value) => {
    const packageItem = packages.find((item) => item.id === 'business');
    const data = {
      ...packageItem,
      userCount: parseInt(value),
    };
    const price = {standart: pricing.standart, business: parseInt(value)};
    setPricing(price);
    const packageList = packages.map((packageItem) => {
      if (packageItem.id === data.id) {
        return data;
      } else {
        return packageItem;
      }
    });
    store.dispatch(setSelectedPackage(data));
    store.dispatch(setPackages(packageList));
  };
  
  
  const onSubmit = (item) => {
    if (item.id === 'enterprise') {
      history.push('/contact-us?type=enterprise');
      return;
    }
    const data = {
      ...item,
      licenseType,
    };
    const packageList = packages.map((packageItem) => {
      if (packageItem.id === data.id) {
        return data;
      } else {
        return packageItem;
      }
    });
    store.dispatch(setSelectedPackage(data));
    store.dispatch(setPackages(packageList));
    storage.set('selectedPackage', data);
    isAuthenticated ? history.push('/pricing/buypackage') : history.push('/login?redirectUrl=/pricing/buypackage');
  };

  const onTrialSubmit = (item) => {
    const data = {
      licenseType,
      ...item,
    };
    const packageList = packages.map((packageItem) => {
      if (packageItem.id === data.id) {
        return data;
      } else {
        return packageItem;
      }
    });
    store.dispatch(setSelectedPackage(data));
    store.dispatch(setPackages(packageList));
    storage.set('selectedPackage', data);
    isAuthenticated ? history.push('/pricing/trial-start') : history.push('/login?redirectUrl=/pricing' + item.buttonUrl);
  };

  return (
    item && <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth:'264px'}}>
      <Card variant="outlined" sx={{
        backgroundColor: item.bgColor,
        border:'1px solid rgba(48, 89, 193, 0.12)',
        '&:hover': {
          boxShadow: ' 0px 28px 53px rgba(51, 21, 137, 0.12);'
        },
        borderRadius: 7.5,
        minHeight: minimal ? '440px':'880px',
        width:'100%',
        marginBottom:{xs:'20px', md:'0px'}
      }}>
        <CardContent sx={{padding:'33px 27px'}}>
          <Box>
            {
              item.id === 'standart' && <img src={StandartIcon} width={48}/>
            }
            {
              item.id === 'business' && <img src={BusinessIcon} width={48}/>
            }
            {
              item.id === 'enterprise' && <img src={EnterpriseIcon} width={48}/>
            }
          </Box>
          <Typography variant={'h5'} sx={{fontWeight: 800, color: '#53B2FF', marginTop:'12px'}}>{t(item.title.toLowerCase())}</Typography>
          <div style={{marginBottom: 10, fontWeight: 600, color: '#53B2FF', fontSize:'13px'}}>
            <small>{t(item.id + 'Benefit1')}</small></div>
          <Box style={{minHeight: '105px'}}>
            <Box>
              <Typography
                color="textSecondary" variant="h5" gutterBottom={true} ml={-1}
                sx={{color:'#26C296',fontWeight: 800, display: 'inline-block', fontSize:item.id === 'enterprise'?'25px !important':'29px !important'}} >
                <span style={{marginLeft:7}}>{item.monthlyAmount && item.annualAmount && (item.id !== 'enterprise'?<CurrencySymbol />:null) }</span>
                {licenseType === 'monthly' ? (item.id === 'enterprise'?t(item.monthlyAmount):(i18n.language === 'ar'?convertDigitIn(item.monthlyAmount):item.monthlyAmount)):(item.id === 'enterprise'?t(item.annualAmount):(i18n.language === 'ar'?convertDigitIn(item.annualAmount):item.annualAmount)) }
                <Typography
                  component="span" style={{marginLeft:6}}
                  sx={{fontWeight: 600}}>
                  {licenseType === 'monthly' ? t(item.id + 'SuffixMonth') : t(item.id + 'SuffixYear')}</Typography>
              </Typography>
              {
                (item.id === 'standart' || item.id === 'business') && <Typography
                  color="textSecondary"
                  sx={{fontWeight: 600, fontSize: 12, marginTop: 1}}>{t(item.id + 'Benefit2') ||
                    <br/>}</Typography>
              }
              {
                (item.id === 'enterprise') && <Typography
                  color="textSecondary"
                  sx={{fontWeight: 600, fontSize: 13, marginTop: 1}}>{t('benefit4') ||
                    <br/>}</Typography>
              }
              {
                (item.id === 'standart' || item.id === 'business') &&
                <Typography color="textSecondary" sx={{fontWeight: 600, fontSize: 12, marginTop: 1}}>{t('benefit3') ||
                  <br/>}</Typography>
              }
             
            </Box>
          </Box>
          <Box width={'100%'} height={'1px'} style={{backgroundColor:'#E3E1EA', marginTop:'41px',marginBottom:'15px'}}/>
          {
            minimal ? <>
              <Box mt={5}>
                <center>
                  <Button
                      onClick={() => history.push('/pricing')}
                      variant='contained'
                      sx={{width: 190, height: 45}}
                  >
                    Detaylı Bilgi
                  </Button>
                </center>
              </Box>
            </> : <>
              <Box>
                {
                  item.details.map((detail,index)=> {
                    return (<Box key={index} width={'100%'} style={{display:'flex', alignItems:'center', marginBottom:'14px'}}>
                      <CheckIcon sx={{color: '#53B2FF', marginRight:'5px'}}/>
                      <Box style={{flex:1,display:'block',color:'#000'}} component={'span'}>{t(detail.id)}</Box>
                      {
                        detail.id === 'userCount' ? <Box>
                          {
                              item.id === 'standart' && <TextField
                                  type="number" size={'small'}
                                  sx={{width: '97px', height:'35px'}}
                                  value={pricing.standart}
                                  inputProps={{max: 10, min: 1, style: {textAlign: 'center'}}}
                                  onChange={(e) => onStandartUserCountChange(e.target.value)}
                                  onInput={(e) => {
                                    if (parseInt(e.target.value) > 10) {
                                      e.target.value = 10;
                                    }
                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                  }}/>
                          }
                          {
                              item.id === 'business' && <TextField
                                  type="number" size={'small'}
                                  sx={{width: '97px', height:'35px'}}
                                  value={pricing.business}
                                  inputProps={{max: 100, min: 11, style: {textAlign: 'center'}}}
                                  onChange={(e) => onBusinessUserCountChange(e.target.value)}
                                  onInput={(e) => {
                                    if (parseInt(e.target.value) > 100) {
                                      e.target.value = 100;
                                    } else if (parseInt(e.target.value) < 11) {
                                      e.target.value = 11;
                                    } else {
                                      if (e.target.value)  {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                      } else {
                                        e.target.value = 11;
                                      }
                                    }
                                  }}/>
                          }
                          {
                              item.id === 'enterprise' && <Box style={{height:'35px', display:'flex', alignItems:'center', direction:'ltr'}}>{t(detail.value)}</Box>
                          }
                        </Box>: <Box style={{direction:'ltr'}}>{t(detail.value)}</Box>
                      }
                    </Box>);
                  })
                }
              </Box>
              <Box mt={5}>
                <center>
                  <Button
                      onClick={() => onSubmit(item)}
                      variant='contained'
                      sx={{width: 190, height: 45}}
                  >
                    {t(item.buttonText)}
                  </Button>
                </center>
              </Box>
            </>
          }
          {item.buyNow &&
              <Box style={{marginTop: '2px'}}>
                <center>
                  <a
                      onClick={() => onTrialSubmit(item)}
                      style={{fontWeight: 600, fontSize: 11, textDecoration: 'underline', cursor: 'pointer'}}
                  >
                    {t('freeTrialStart')}
                  </a>
                </center>
              </Box>
          }
        </CardContent>
      </Card>
    </Box>


  );
};

PricingCard.propTypes = {
  item: PropTypes.any,
  licenseType: PropTypes.string,
  minimal:PropTypes.bool
};


export default PricingCard;
