import React from 'react';
import Box from '@mui/material/Box';
import privacyPolicyBack2 from '../svg/logos/privacyPolicyBack2.svg';
import privacyPolicyBack1 from '../svg/logos/privacyPolicyBack1.svg';
import PropTypes from 'prop-types';


const BgBottomFigure = ({style}) => (
  <Box
    sx={{
      width: '100%',
      height: '205px',
      position: 'relative',
      marginBottom: -1,
      '&:after': {
        content: '""',
        position: 'absolute',
        backgroundImage: `url(${privacyPolicyBack2})`,
        height: '150px',
        left: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%'
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        backgroundImage: `url(${privacyPolicyBack1})`,
        height: '248px',
        boxShadow:'inset 0px 3px 60px #FFFFFF2E',
        left: 0,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        bottom: '0',
        width: '100%'
      },
      ...style
    }}
  />
);

BgBottomFigure.propTypes = {
  style: PropTypes.object,
};

export default BgBottomFigure;
