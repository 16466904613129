import { Ajax } from '../utils/index';

export default {
  getMeetings (data) {
    let promise = Ajax.postPromise('/meeting/get-meetings', data);
    return promise;
  },
  getMeetingsForDashboard (withHistory) {
    let promise = Ajax.getPromise('/meeting/get-meetings-for-dashboard/' + withHistory);
    return promise;
  },
  getMeetingDetail (id) {
    let promise = Ajax.getPromise('/meeting/get-meeting-detail/' + id);
    return promise;
  },
  getMeetingRecords (meetingId) {
    let promise = Ajax.getPromise('/meeting/meeting-records/' + meetingId);
    return promise;
  },
  getAllMeetingRecords (data) {
    let promise = Ajax.postPromise('/meeting/get-meetings-for-recordings', data);
    return promise;
  },
  createMeeting (data) {
    let promise = Ajax.postPromise('/meeting/create-meeting-v2', data);
    return promise;
  },
  editMeeting (data) {
    let promise = Ajax.postPromise('/meeting/edit-meeting', data);
    return promise;
  },
  createQuicklyMeeting (meetingName) {
    let promise = Ajax.getPromise('/meeting/create-quickly-meeting/' + meetingName);
    return promise;
  },
  createShortUrl (meetingUrl) {
    let promise = Ajax.postPromise('https://link.ydyazilim.com/api/short', {
      'origUrl': meetingUrl
    });
    return promise;
  },
  joinMeeting (data) {
    let promise = Ajax.postPromise('/meeting/join', data);
    return promise;
  },
  editDetail (meetingId) {
    let promise = Ajax.getPromise('/meeting/edit-detail/' + meetingId);
    return promise;
  },
  liveEditDetail (meetingId) {
    let promise = Ajax.getPromise('/meeting/live-edit-detail/' + meetingId);
    return promise;
  },
  goMeeting (meetingId) {
    let promise = Ajax.getPromise('/meeting/go-meeting/' + meetingId);
    return promise;
  },
  registerMeeting (meetingId) {
    let promise = Ajax.getPromise('/meeting/register-meeting/' + meetingId);
    return promise;
  },
  suspendMeeting (meetingId) {
    let promise = Ajax.getPromise('/meeting/suspend-meeting/' + meetingId);
    return promise;
  },
  getMeetingCalendarModal (meetingId) {
    let promise = Ajax.getPromise('/meeting/get-meeting-calendar-modal/' + meetingId);
    return promise;
  },
  getIsMeetingLive (meetingId) {
    let promise = Ajax.getPromise('/meeting/get-is-meeting-live/' + meetingId);
    return promise;
  },
  getOnlineUsers () {
    let promise = Ajax.getPromise('meeting/get-online-user');
    return promise;
  },
  getMyCalls () {
    let promise = Ajax.getPromise('meeting/get-my-calls');
    return promise;
  }
};
