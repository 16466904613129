import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import heroImage from 'svg/misc/heroImage.jpg';
import heroImage2 from 'svg/misc/heroImage2.jpg';
import mobileHeroImage from 'svg/misc/mobileHeroImage.jpg';
import mobileHeroImage2 from 'svg/misc/mobileHeroImage2.jpg';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Typography, Button, Link, Container} from '@mui/material';
import {useTranslation} from 'react-i18next';

const HeroSlider = () => {
  const {t, i18n} = useTranslation();
  const theme = useTheme();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const currentSlideDelay = 3000;
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });
  const [sliders, setSliders] = useState([
    {
      link: '/features#section2',
      image: isMd ? heroImage2 : mobileHeroImage2,
      title: t('fastConnection'),
      text: t('fastConnectionText'),
      button: t('detailInformation')
    },
    {
      link: '/features#section1',
      image: isMd ? heroImage : mobileHeroImage,
      title: t('landingPageFourthSlide'),
      text: t('landingPageFourthSlideText'),
      button: t('detailInformation')
    }
  ]);
  const sliderOpts = {
    customPaging: function Bla(pagi) {
      return <div className={pagi === currentSlideIndex ? 'slick-active' : ''}>
        <span
          className={'number'}>{t(pagi + 1)}
        </span>
        <div className={'progress-bar'}></div>
      </div>;
    },
    beforeChange: (prev, next) => {
      setCurrentSlideIndex(next);
    },
    dotsClass: 'slick-dots slick-thumb',
    dots: true,
    fade: true,
    infinite: true,
    speed: currentSlideDelay,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    // eslint-disable-next-line react/display-name
    appendDots: dots => {
      return (
        <div>
          <div style={{width: 90, overflow: 'hidden'}}>
            <ul style={{
              padding: 0,
              width: dots.length * 94,
              marginLeft: -74 * (dots.length === (currentSlideIndex + 1) ? currentSlideIndex - 1 : currentSlideIndex),
              transition: 'all .3s ease-in-out'
            }}>
              {dots.map((item, index) => {
                return (
                  <li key={index}>{item.props.children}</li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    },
  };

  useEffect(() => {
    setSliders([
      {
        link: '/features#section2',
        image: isMd ? heroImage2 : mobileHeroImage2,
        title: t('fastConnection'),
        text: t('fastConnectionText'),
        button: t('detailInformation')
      },
      {
        link: '/features#section1',
        image: isMd ? heroImage : mobileHeroImage,
        title: t('landingPageFourthSlide'),
        text: t('landingPageFourthSlideText'),
        button: t('detailInformation')
      }
    ]);
  }, [i18n.language]);

  return (
    <Box
      sx={{
        '& .slick-slider .slick-dots': {
          bottom: '30px',
          textAlign: 'left',
          paddingLeft: '24px',
          paddingRight: '24px',
          marginLeft: 'auto',
          marginRight: 'auto',
          left: 0,
          right: 0,
          maxWidth: '1200px'
        },
        '& .slick-slider .slick-dots.slick-thumb': {
          paddingRight: 1
        },
        '& .slick-slider .slick-dots.slick-thumb li': {
          height: '1px',
          margin: '0',
          width: '70px',
          transition: 'all 0.5s ease-in-out',
          willChange: 'width',
          marginRight: '6px',
          '& div': {
            opacity: '0.5',
            '& .progress-bar': {
              position: 'absolute',
              top: '13px',
              right: 0,
              width: '55px',
              height: '1px',
              '&::after': {
                content: '""',
                width: '100%',
                transition: 'all .6s ease-in-out',
                position: 'absolute',
                left: 0,
                right: 0,
                backgroundColor: '#fff',
                top: 0,
                bottom: 0
              },
            },
          },
          '& .number': {
            color: '#fff'
          },
          '&:last-child': {
            width: 'auto',
            'div': {
              '& .progress-bar::after': {
                display: 'none'
              }
            }
          }
        },
        '& .slick-slider .slick-dots.slick-thumb li .slick-active': {
          opacity: '1',
          '& .progress-bar': {
            opacity: '1',
          }
        }
      }}>
      <Slider {...sliderOpts} >
        {sliders.map((item, i) => {
          return (
            <Box key={i} position={'relative'}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 2,
                  display: 'flex'
                }}
              >
                <Container style={{display: 'flex', alignItems: 'center'}}>
                  <Box maxWidth={{xs: '250px', sm: '100%'}}>
                    <Typography
                      variant={'h4'}
                      gutterBottom
                      sx={{fontWeight: 700, fontSize: '36px !important', color: '#fff'}}
                    >
                      {item.title}
                    </Typography>
                    <Typography color="#fff" sx={{fontSize: 18}}>
                      {item.text}
                    </Typography>
                    <Button
                      variant="contained"
                      component={Link}
                      href={item.link}
                      color="secondary"
                      size="large"
                      sx={{color: '#53B2FF', fontWeight: 700, width:'200px', marginTop: 5}}
                    >
                      {item.button}
                    </Button>
                  </Box>
                </Container>
              </Box>
              <Box
                component={'a'}
                display={'block'}
                href={item.link}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    background: 'linear-gradient(90deg, #000000 0.83%, rgba(0, 0, 0, 0) 75.51%)',
                  },
                }}
              >
                <Box
                  component={'img'}
                  src={item.image}
                  display={'flex'}
                  flexDirection={'column'}
                  minHeight={{md: '370px', sm: '370px', xs: '400px'}}
                  style={{objectFit: 'cover'}}
                  width={'100%'}
                  height={'auto'}
                >
                </Box>
              </Box>
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
};

export default HeroSlider;
