/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Container} from '@mui/material';

const Hero = () => {
    const {t, i18n} = useTranslation();
    const history = useHistory();
    const isAuthenticated = useSelector(state => state.main.isAuthenticated);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });

    return (<Box style={{background:'#F0F5FF'}}>
        <Container>
            <Grid sx={{marginTop:{xs:'-30px',md:'-80px'}}} minHeight="400px" container>
                <Grid
                    item container xs={12} md={isAuthenticated ? 12 : 6}
                    sx={{paddingRight:{xs:'0px',md:'15px'}}}>
                    <Box width={'100%'}
                         data-aos={isMd ? 'fade-right' : 'fade-up'}
                         display="flex"
                         flexDirection={'column'}
                         justifyContent={'center'}
                         position={'relative'}
                         sx={{
                             height:{xs:'250px',md:'300px'},
                             borderRadius: '20px',
                             backgroundImage: 'linear-gradient(106deg, #FFFCD6 1.67%, #E4FDFF 1.68%, #F9FFF1 97.46%)',
                             '&:after': {content: '""',
                                 backgroundImage: 'linear-gradient(105deg, #FFEAEC 0%, #D8F1FF 100%);',
                                 left:0,right:0,top:0,bottom:0,
                                 position:'absolute',
                                 opacity:0,
                                 borderRadius: '20px',
                                 transition:'all 1s ease-in-out'},
                             '&:hover:after': {opacity: 1}
                         }}>
                        <Box
                            sx={{
                                paddingX:{xs:'10px',md:'48px'}}}
                            position={'relative'} zIndex={2}>
                            <Box marginBottom={2} textAlign={'center'}>


                            <Box textAlign="center" marginBottom={'10px'}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() => history.push(isAuthenticated ? '/profile/meetings?createNow' : '/login?redirectUrl=profile/meetings?createNow')}
                                    sx={{fontWeight: 500}}
                                >
                                    {t('createQuiclyMeetingNowButton')}
                                </Button>
                            </Box>



                                <Typography variant="h5" color="#424242" sx={{fontWeight: 700}}>
                                    {t('createQuiclyMeetingNow')}
                                </Typography>



                                <Box style={{minHeight: '80px'}}>
                                    <Typography fontSize={'15px'}
                                                color="#424242"
                                                marginY={2}
                                    >
                                        {t('createQuiclyMeetingNowText1')}
                                        {t('createQuiclyMeetingNowText2')}
                                    </Typography>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item container justifyContent={'center'} xs={12} md={6}
                    sx={{paddingLeft:{xs:'0px',md:'15px'},marginTop:{xs:'20px',md:'0px'}}}
                    display={isAuthenticated ? 'none' : 'flex'}>
                    <Box width={'100%'}
                         alignItems={'center'}
                         data-aos={isMd ? 'fade-right' : 'fade-up'} display="flex"
                         flexDirection={'column'}
                         justifyContent={'center'}
                         position={'relative'}
                         sx={{
                             height:{xs:'250px',md:'300px'},
                             borderRadius: '20px',
                             backgroundImage: 'linear-gradient(106deg, #FFFCD6 1.67%, #E4FDFF 1.68%, #F9FFF1 97.46%)',
                             '&:after': {content: '""',
                                        backgroundImage: 'linear-gradient(105deg, #FFEAEC 0%, #D8F1FF 100%);',
                                        left:0,right:0,top:0,bottom:0,
                                        position:'absolute',
                                        opacity:0,
                                        borderRadius: '20px',
                                        transition:'all 1s ease-in-out'},
                             '&:hover:after': {opacity: 1}
                         }}>
                        <Box
                            sx={{
                                paddingX:{xs:'10px',md:'48px'}}}
                            position={'relative'} zIndex={2}>
                            <Box marginBottom={2} textAlign={'center'}
                            >

<Box
                                textAlign="center"
                                marginBottom={'10px'}>
                                <Button
                                    variant="contained"
                                    onClick={() => history.push('/signup')}
                                    color="primary"
                                    size="large"
                                    sx={{color: '#fff', fontWeight: 500}}
                                >
                                    {t('freeSignUp')}
                                </Button>
                            </Box>

                                <Typography
                                    variant="h5"
                                    color="#424242"
                                    sx={{
                                        fontWeight: 700
                                    }}
                                >
                                    {t('freeSignUp')}
                                </Typography>
                                <Box style={{minHeight: '80px'}}>
                                    <Typography fontSize={'15px'}
                                                color="#424242"
                                                marginY={2}
                                    >
                                        {t('freeSignUpText')}
                                        {t('freeSignUpText2')}
                                    </Typography>
                                </Box>

                            </Box>

                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </Box>);
};

export default Hero;
