import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import LogoS from 'svg/logos/LogoRenkli.svg';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import UserDropdown from '../UserDropdown/UserDropdown';
import {useTranslation} from 'react-i18next';
import {PiUser} from 'react-icons/pi';

const Topbar = ({onSidebarOpen}) => {
  const {t, i18n} = useTranslation();
  let history = useHistory();
  const user = useSelector((state) => state.main.user);

  // eslint-disable-next-line no-unused-vars
  const [videoCallModalShow, setVideoCallModalShow] = useState(false);
  const isAuthenticated = useSelector((state) => state.main.isAuthenticated);

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
    >
      <Box display={'flex'} alignItems={'center'}>
        <Box
          display={'flex'}
          alignItems="baseline"
          component="a"
          underline="none"
          style={{
            cursor: 'pointer',
            display: 'block',
            marginRight:i18n.language?'0':0
          }}
          onClick={() => history.push('/')}
          height={60}
          paddingTop={{xs: '6px', md: '8px'}}
          marginLeft={0}
        >
          <img src={LogoS} height={50} alt=""/>
        </Box>
      </Box>
      <Box display="flex" alignItems={'center'} justifyContent={'center'} flex={1}>
        <Box sx={{marginRight:'8px',marginLeft:'10px', display: {xs: 'none', md: 'flex'}}} alignItems={'center'}>
          <Box>
            <Link color="#89A0D3" fontSize={'15px'} component={Button} underline="none" cursor="pointer" onClick={() => history.push('/features')} sx={{fontWeight: 500}}>
              {t('features')}
            </Link>
          </Box>
        </Box>
        <Box sx={{marginRight:'8px',marginLeft:'10px', display: {xs: 'none', md: 'flex'}}}>
          <Link color="#89A0D3" fontSize={'15px'} component={Button} underline="none" cursor="pointer" onClick={() => history.push('/pricing')} sx={{fontWeight: 500}}>
            {t('buyNow')}
          </Link>
        </Box>
        <Box sx={{marginRight:'8px',marginLeft:'10px', display: {xs: 'none', md: 'flex'}}} alignItems={'center'} marginRight={2}>
          <Link color="#89A0D3" fontSize={'15px'} component={Button} underline="none" cursor="pointer" onClick={() => history.push('/contact-us')} sx={{fontWeight: 500}}>
            {t('contactUs')}
          </Link>
        </Box>
      </Box>
      <Box display="flex" alignItems={'center'}>
        {isAuthenticated ?
          <Box sx={{display: {xs: 'none', md: 'flex'}}} alignItems={'center'} marginLeft={1}>
            <UserDropdown user={user}/>
          </Box> : <>
              <Link bgcolor={'#53B2FF'}  color="#fff" fontSize={'15px'} component={Button} underline="none" cursor="pointer" onClick={() => history.push('/login')}
                    sx={{display: {xs: 'none', md: 'block'},fontWeight: 500,padding:'15px 15px', borderRadius: '10px !important', fontSize:'14px',
                          '&:hover': {
                              backgroundColor:'#53B2FF !important'
                          }}}>
                <PiUser style={{marginRight:'4px'}} /> {t('accountSignIn')}
              </Link>
            </>
        }
        <Box sx={{display: {xs: 'none', md: 'flex', marginRight:i18n.language==='ar'?'10px':'0'}}} alignItems={'center'} marginLeft={1}>
          <LanguageSwitcher/>
        </Box>

        <Box marginRight={{xs: 1, sm: 2}} sx={{display: {xs: 'flex', md: 'none'}}}>
          <IconButton
            onClick={onSidebarOpen} aria-label="Menu"
            sx={{backgroundColor: 'transparent', color: '#53B2FF'}}>
            <MenuIcon style={{fontSize:'30px'}}/>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func
};

export default Topbar;

