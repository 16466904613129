import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ChangePassword from 'views/authPages/ChangePassword/ChangePassword';
import BuyPackage from 'views/supportingPages/Pricing/BuyPackage/BuyPackage';
import TrialStart from 'views/supportingPages/Pricing/TrialStart/TrialStart';
import ContactUs from 'views/supportingPages/ContactUs';
import WithLayout from 'WithLayout';
// Available layouts
import { BodyMain as BodyLayout, Main as MainLayout } from '../layouts/index';
// Authentication pages
import {
  ForgotPasswordSimple as ForgotPasswordSimpleView,
  LoginSimple as LoginSimpleView,
  SignupSimple as SignupSimpleView
} from '../views/authPages';
// Landing pages
import { Home as HomeView } from '../views/landingPages';
// Supporting pages
import { MeetingGo, NotFound as NotFoundView, Pricing, PrivacyPolicy, Profile } from '../views/supportingPages';
import AuthRoute from './authRoute';
import SalesPolicy from '../views/supportingPages/Policy/SalesPolicy';
import Features from '../views/supportingPages/Features';

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/privacy-policy"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={PrivacyPolicy}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/sales-policy"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={SalesPolicy}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={HomeView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/account/company-approval"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={HomeView}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/:lang/meeting/go/:meetingId"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={MeetingGo}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/pricing"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Pricing}
            layout={MainLayout}
          />
        )}
      />
      <AuthRoute
        path="/pricing/trial-start"
        type="private"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={TrialStart}
            layout={MainLayout}
          />
        )}
      />
      <AuthRoute
        path="/pricing/buypackage"
        type="private"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={BuyPackage}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/login"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={LoginSimpleView}
            layout={BodyLayout}
          />

        )}>
      </Route>

      <Route
        exact
        path="/contact-us"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={ContactUs}
            layout={MainLayout}
          />
        )}
      />

      <Route
        exact
        path="/features"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Features}
            layout={MainLayout}
          />
        )}
      />

      <AuthRoute
        exact
        path="/:lang/account/accountverification"
        type="guest"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={LoginSimpleView}
            layout={BodyLayout}
          />

        )}>
      </AuthRoute>

      <Route
        path="/:lang/account/password-verification-check"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={ChangePassword}
            layout={BodyLayout}
          />
        )}
      />

      <AuthRoute
        type="guest"
        exact
        path="/signup"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={SignupSimpleView}
            layout={BodyLayout}
          />
        )}
      />

      <AuthRoute
        type="guest"
        exact
        path="/page-forgot-password-simple"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={ForgotPasswordSimpleView}
            layout={MainLayout}
          />
        )}
      />
      <AuthRoute
        type="private"
        path="/profile/:title"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Profile}
            layout={MainLayout}
          />
        )}
      />
      <AuthRoute
        type="private"
        exact
        path="/profile"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Profile}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/page-not-found"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={NotFoundView}
            layout={MainLayout}
          />
        )}
      />
      <Redirect to="/page-not-found"/>
    </Switch>
  );
};

export default Routes;
