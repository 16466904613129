import React, {useEffect} from 'react';
import {Box, Link, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import {BrowserRouter} from 'react-router-dom';
import BgBottomFigure from '../../../common/BgBottomFigure';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

function SalesPolicy({isModal}) {
  const {t} = useTranslation();
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  return (
    <div>
      <Grid container p={10} paddingTop={{md: '83px', xs: '20px'}} style={{marginBottom: '50px'}}>
        {!isModal && <Grid item md={2}/>}
        <Grid item md={isModal ? 12 : 8}>
          <BrowserRouter>
            <Typography
              variant="h1"
              fontSize={{md: '3rem', xs: '2rem'}}
              fontWeight={700}
              color={'#000'}
              mb={2}
            >
              {t('termsOfConditions')}
            </Typography>
            <Box borderBottom={'3px solid #44327E'}/>
            <Navigator/>
            <PolitikaHakkinda/>
            <HaklarVeKontroller/>
            <HakkinizdaTopladigimizKisiselVeriler/>
            <KullanmaAmacimiz/>
            <VerilerinPaylasilmasi/>
            <VerilerinSaklanmasiSilinmesi/>
            <VerilerinGuvendeTutulmasi/>
            <BizimleIletisimeGecme/>
            <SozlesmeSuresi/>
          </BrowserRouter>
        </Grid>
        {!isModal && <Grid item md={2}/>}
      </Grid>
      {!isModal && <BgBottomFigure/>}
    </div>
  );
}

function Navigator() {
  const {t} = useTranslation();
  function goToSection(e, sectionName) {
    let elem = document.getElementById(sectionName);
    e.preventDefault();
    elem && elem.scrollIntoView({behavior: 'smooth', block: 'start'});
  }

  return (
    <Box
      paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '20px', xs: '20px'}}
      sx={{'& a': {color: '#9A78FB', textDecoration: 'none'}}}>
      <Link
        fontSize={{md: '25px', xs: '16px'}}
        style={{cursor: 'pointer'}}
        onClick={e => goToSection(e, 'PolitikaHakkinda')}
      >
        {t('salesPolicyItem1Title')}
        
      </Link>
      <br/>
      <Link
        fontSize={{md: '25px', xs: '16px'}}
        style={{cursor: 'pointer'}}
        onClick={e => goToSection(e, 'HaklarVeKontroller')}
      >
        {t('salesPolicyItem2Title')}
        
      </Link>
      <br/>
      <Link
        fontSize={{md: '25px', xs: '16px'}}
        style={{cursor: 'pointer'}}
        onClick={e => goToSection(e, 'HakkinizdaTopladigimizKisiselVeriler')}
      >
        {t('salesPolicyItem3Title')}
        
      </Link>
      <br/>
      <Link
        fontSize={{md: '25px', xs: '16px'}}
        style={{cursor: 'pointer'}}
        onClick={e => goToSection(e, 'KullanmaAmacimiz')}
      >
        {t('salesPolicyItem4Title')}
        
      </Link>
      <br/>
      <Link
        fontSize={{md: '25px', xs: '16px'}}
        style={{cursor: 'pointer'}}
        onClick={e => goToSection(e, 'VerilerinPaylasilmasi')}
      >
        {t('salesPolicyItem5Title')}
        
      </Link>
      <br/>
      <Link
        fontSize={{md: '25px', xs: '16px'}}
        style={{cursor: 'pointer'}}
        onClick={e => goToSection(e, 'VerilerinSaklanmasiSilinmesi')}
      >
        {t('salesPolicyItem6Title')}
        
      </Link>
      <br/>
      <Link
        fontSize={{md: '25px', xs: '16px'}}
        style={{cursor: 'pointer'}}
        onClick={e => goToSection(e, 'VerilerinGuvendeTutulmasi')}
      >
        {t('salesPolicyItem7Title')}
        
      </Link>
      <br/>
      <Link
        fontSize={{md: '25px', xs: '16px'}}
        style={{cursor: 'pointer'}}
        onClick={e => goToSection(e, 'BizimleIletisimeGecme')}
      >
        {t('salesPolicyItem8Title')}
        
      </Link>
      <br/>
      <Link
        fontSize={{md: '25px', xs: '16px'}}
        style={{cursor: 'pointer'}}
        onClick={e => goToSection(e, 'SozlesmeSuresi')}
      >
        {t('salesPolicyItem9Title')}
        
      </Link>
      <br/>
    </Box>
  );
}

function PolitikaHakkinda() {
  const {t} = useTranslation();
  return (
    <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
      <section id="PolitikaHakkinda" style={{height: '900', color: '#000'}}>
        <Typography
          variant="h1"
          fontSize={{md: '30px', xs: '20px'}}
          fontWeight={700}
          marginBottom={{md: '20px', xs: '10px'}}
          color={'#000'}>{t('salesPolicyItem1Title')}</Typography>
        <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'} dangerouslySetInnerHTML={{__html:t('salesPolicyItem1Text')}}>
        </Typography>
      </section>
    </Box>
  );
}

function HaklarVeKontroller() {
  const {t} = useTranslation();
  return (
    <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
      <section id="HaklarVeKontroller" style={{height: '900', color: '#000'}}>
        <Typography
          variant="h1"
          fontSize={{md: '30px', xs: '20px'}}
          fontWeight={700}
          marginBottom={{md: '20px', xs: '10px'}}
          color={'#000'}>{t('salesPolicyItem2Title')}</Typography>
        <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'} dangerouslySetInnerHTML={{__html:t('salesPolicyItem2Text')}}>
        </Typography>
      </section>
    </Box>
  );
}

function HakkinizdaTopladigimizKisiselVeriler() {
  const {t} = useTranslation();
  return (
    <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
      <section id="HakkinizdaTopladigimizKisiselVeriler" style={{height: '900', color: '#000'}}>
        <Typography
          variant="h1"
          fontSize={{md: '30px', xs: '20px'}}
          fontWeight={700}
          marginBottom={{md: '20px', xs: '10px'}}
          color={'#000'}>{t('salesPolicyItem3Title')}</Typography>
        <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'} dangerouslySetInnerHTML={{__html:t('salesPolicyItem3Text')}}>
        </Typography>
      </section>
    </Box>
  );
}

function KullanmaAmacimiz() {
  const {t} = useTranslation();
  return (
    <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
      <section id="KullanmaAmacimiz" style={{height: '900', color: '#000'}}>
        <Typography
          variant="h1"
          fontSize={{md: '30px', xs: '20px'}}
          fontWeight={700}
          marginBottom={{md: '20px', xs: '10px'}}
          color={'#000'}>{t('salesPolicyItem4Title')}</Typography>
        <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'} dangerouslySetInnerHTML={{__html:t('salesPolicyItem4Text')}}>
        </Typography>
      </section>
    </Box>
  );
}

function VerilerinPaylasilmasi() {
  const {t} = useTranslation();
  return (
    <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
      <section id="VerilerinPaylasilmasi" style={{height: '900', color: '#000'}}>
        <Typography
          variant="h1"
          fontSize={{md: '30px', xs: '20px'}}
          fontWeight={700}
          marginBottom={{md: '20px', xs: '10px'}}
          color={'#000'}>{t('salesPolicyItem5Title')}</Typography>
        <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'} dangerouslySetInnerHTML={{__html:t('salesPolicyItem5Text')}}>
        </Typography>
      </section>
    </Box>
  );
}

function VerilerinSaklanmasiSilinmesi() {
  const {t} = useTranslation();
  return (
    <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
      <section id="VerilerinSaklanmasiSilinmesi" style={{height: '900', color: '#000'}}>
        <Typography
          variant="h1"
          fontSize={{md: '30px', xs: '20px'}}
          fontWeight={700}
          marginBottom={{md: '20px', xs: '10px'}}
          color={'#000'}>{t('salesPolicyItem6Title')}</Typography>
        <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'} dangerouslySetInnerHTML={{__html:t('salesPolicyItem6Text')}}>
        </Typography>
      </section>
    </Box>
  );
}

function VerilerinGuvendeTutulmasi() {
  const {t} = useTranslation();
  return (
    <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
      <section id="VerilerinGuvendeTutulmasi" style={{height: '900', color: '#000'}}>
        <Typography
          variant="h1"
          fontSize={{md: '30px', xs: '20px'}}
          fontWeight={700}
          marginBottom={{md: '20px', xs: '10px'}}
          color={'#000'}>{t('salesPolicyItem7Title')}</Typography>
        <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'} dangerouslySetInnerHTML={{__html:t('salesPolicyItem7Text')}}>
        </Typography>
      </section>
    </Box>
  );
}

function BizimleIletisimeGecme() {
  const {t} = useTranslation();
  return (
    <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
      <section id="BizimleIletisimeGecme" style={{height: '900', color: '#000'}}>
        <Typography
          variant="h1"
          fontSize={{md: '30px', xs: '20px'}}
          fontWeight={700}
          marginBottom={{md: '20px', xs: '10px'}}
          color={'#000'}>{t('salesPolicyItem8Title')}</Typography>
        <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'} dangerouslySetInnerHTML={{__html:t('salesPolicyItem8Text')}}>
        </Typography>
      </section>
    </Box>
  );
}

function SozlesmeSuresi() {
  const {t} = useTranslation();
  return (
    <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
      <section id="SozlesmeSuresi" style={{height: '900', color: '#000'}}>
        <Typography
          variant="h1"
          fontSize={{md: '30px', xs: '20px'}}
          fontWeight={700}
          marginBottom={{md: '20px', xs: '10px'}}
          color={'#000'}>{t('salesPolicyItem9Title')}</Typography>
        <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'} dangerouslySetInnerHTML={{__html:t('salesPolicyItem9Text')}}>
        </Typography>
      </section>
    </Box>
  );
}

SalesPolicy.propTypes = {
  isModal: PropTypes.bool,
};

export default SalesPolicy;
