import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import PlanMeetingEditForm from './components/PlanMeetingEditForm';
import {useTranslation} from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs: '95%', md: 800},
  maxHeight: {xs: '85%', md: 1000},
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  p: 2,
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '0px',
  },
};

const EditMeeting = ({visible, setVisible, selectedMeeting, getMeetings}) => {
  const {t} = useTranslation();

  return (
    <Modal
      open={visible}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{textAlign: 'end', height: 50}}>
          <IconButton onClick={() => setVisible(false)}>
            <CloseIcon/>
          </IconButton>
        </Box>

        <div>
          <Accordion
            expanded={true}
            square
            sx={{
              borderRadius: 5, width: {sm: '100%', md: '100%', backgroundColor: '#EFEFEF'}, overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '0px',
              },
            }}
          >
            <AccordionSummary
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              sx={{height: 80, borderRadius: 5}}
            >
              <InfoIcon sx={{marginTop: 2, marginRight: 2, color: 'gray'}}/>
              <Typography
                variant='h5'
                sx={{color: 'text.tertiary', fontWeight: 700, marginTop: 1}}
              >
                {t('editMeeting')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{
              paddingBottom: 5
            }}>
              <PlanMeetingEditForm
                setVisible={setVisible}
                selectedMeeting={selectedMeeting}
                getMeetings={getMeetings}
              />
            </AccordionDetails>
          </Accordion>
        </div>

      </Box>
    </Modal>
  );
};

EditMeeting.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  getMeetings: PropTypes.func,
  selectedMeeting: PropTypes.object,
};

export default EditMeeting;
