import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {Container} from '@mui/material';
import {IoShieldCheckmarkOutline} from 'react-icons/io5';
import appStore from 'svg/appsection/app-store.svg';
import googlePlay from 'svg/appsection/google-play.svg';
import appBG from 'svg/appsection/bg.svg';
import desktop from 'svg/appsection/desktop.svg';
import mobile from 'svg/appsection/mobile.svg';
import message from 'svg/appsection/message.svg';
import checkMessage from 'svg/appsection/checkmessage.svg';
import {Parallax} from 'react-scroll-parallax';


const AppSection = () => {
	return (
		<Box style={{overflow: 'hidden'}}>
			<Box style={{backgroundColor: '#F0F5FF', position: 'relative'}}>
				<Box sx={{position:'absolute'}}>
					<Parallax
						translateX={['-400px', '0px']}
						scale={[0.75, 1]}
						rotate={[-180, 0]}
						easing="easeInQuad"
					>
						<img src={message} style={{height: '81px'}}/>
					</Parallax>
					<Parallax
						translateX={['500px', '100px']}
						translateY={['100px', '100px']}
						scale={[0.75, 1]}
						rotate={[-180, 0]}
						easing="easeInQuad"
					>
						<img src={checkMessage} style={{height: '47px'}}/>
					</Parallax>
				</Box>
				<Container>
					<Grid container spacing={3} justifyContent={{xs: 'center', md: 'space-between'}}>
						<Grid item xs={12} md={6} justifyContent={'flex-end'}>
							<Box sx={{minHeight: {xs:'auto', md:'760px'}, display: 'flex', alignItems: 'center'}}>
								<Box>
									<Box style={{textAlign: 'left', marginBottom: '35px'}}>
										<h2 style={{color: '#424242', fontSize: '26px'}}>Anında Toplantı ve Görüşmeler İçin Hızlı Çözüm
										</h2>
									</Box>
									<Box style={{textAlign: 'left', marginBottom: '25px'}}>
                                <span style={{color: '#424242', fontSize: '15px'}}>
                              Mobil ve web uygulamaları için mükemmel bir deneyim sunan  Telepati ile tanışın. Sistemimiz, işlevselliği ve kullanıcı dostu arayüzüyle öne çıkıyor.
                                </span>
									</Box>
									<Box style={{textAlign: 'left', marginBottom: '45px'}}>
										<ul style={{listStyle: 'none', padding: 0}}>
											<li style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
												<IoShieldCheckmarkOutline style={{color: '#53B2FF', marginRight: '4px'}}/>
												<span style={{color: '#424242', fontSize: '16px'}}>Masaüstü ve Mobil planformlarımızla bir adım öne geçin.</span>
											</li>
											<li style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
												<IoShieldCheckmarkOutline style={{color: '#53B2FF', marginRight: '4px'}}/>
												<span style={{color: '#424242', fontSize: '16px'}}>Telepati Mobili kullanarak Verimli Çalışma Ortamı yakalayabilirsiniz.</span>
											</li>
											<li style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
												<IoShieldCheckmarkOutline style={{color: '#53B2FF', marginRight: '4px'}}/>
												<span style={{color: '#424242', fontSize: '16px'}}>Etkili ve Kesintisiz iletişim için ... </span>
											</li>
											<li style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
												<IoShieldCheckmarkOutline style={{color: '#53B2FF', marginRight: '4px'}}/>
												<span style={{color: '#424242', fontSize: '16px'}}>Toplantılarınızı canlı ve verimli yönetin.</span>
											</li>
										</ul>
									</Box>
									<Box style={{textAlign: 'left', marginBottom: '45px'}}>
										<a style={{display: 'inline-block', marginRight: '5px'}}>
											<img src={appStore} height={52}/>
										</a>
										<a style={{display: 'inline-block', marginRight: '5px', zIndex: 1}}>
											<img src={googlePlay} height={52}/>
										</a>
									</Box>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Container>
				<Box sx={{display: {xs:'none',md:'block'}, position: {xs:'relative',md:'absolute'}, right: 0, top: 0, bottom: 0, minHeight:'400px'}}>
					<div style={{position: 'absolute', top: '0', right: '0', textAlign:'right'}}>
						<Parallax speed={-10}>
							<Box component={'img'} src={desktop} sx={{height: {xs:'auto',md:'758px'}, width: {xs:'90%',md:'auto'}}}/>
						</Parallax>
						<Parallax speed={10}>
							<Box component={'img'} src={mobile} sx={{height: {xs:'auto',md:'627px'},width: {xs:'60%',md:'auto'}, position: 'absolute', left: '100px', bottom: '-150px'}}/>
						</Parallax>
					</div>
				
				</Box>
			</Box>
			<Box sx={{
				backgroundImage: `url(${appBG})`,
				backgroundRepeat: 'repeat',
				height: '200px',
				backgroundSize: 'cover'
			}}>
			
			</Box>
		</Box>
	);
};

export default AppSection;
