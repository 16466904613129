import React from 'react';
import Box from '@mui/material/Box';
import Container from 'common/Container';
import { Form } from './components';

// is called from LoginSimple/Form as a modal
const ForgotPasswordSimple = () => {
  return (
    <Box
      position={'relative'}
      minHeight={'calc(50vh)'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      height={'100%'}
      sx={{ borderBottom: 'none', borderTop: 'none' }}
    >
      <Container maxWidth={500}>
        <Form />
      </Container>
    </Box>
  );
};

export default ForgotPasswordSimple;
