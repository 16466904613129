export const convertDigitIn = (enDigit) => {
  var newValue='';
  for (var i=0;i<enDigit.length;i++)
  {
    var ch=enDigit.charCodeAt(i);
    if (ch>=48 && ch<=57)
    {
      // european digit range
      var newChar=ch+1584;
      newValue=newValue+String.fromCharCode(newChar);
    }
    else
      newValue=newValue+String.fromCharCode(ch);
  }
  return newValue;
};

export default {
  convertDigitIn
};