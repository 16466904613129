import CloseIcon from '@mui/icons-material/Close';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Language';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {Button, Divider, List, ListItem, ListItemText, Typography} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import handleSignOut from 'utils/signout';
import {useCookies} from 'react-cookie';
import {useTranslation} from 'react-i18next';

const LangList = [
  {
    title: 'Türkçe',
    page: 'tr'
  },
  {
    title: 'English',
    page: 'en'
  },
  { page: 'ar', title: 'عربي' },
  { page: 'ru', title: 'Русский' }
];

const SidebarNav = ({onClose}) => {

  const dispatch = useDispatch();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const {t, i18n} = useTranslation();
  const [profileMenuList, setProfileMenuList] = useState([]);
  const [expanded, setExpanded] = useState('');
  const isAuthenticated = useSelector((state) => state.main.isAuthenticated);
  // eslint-disable-next-line no-unused-vars
  const [cookie, setCookie, removeCookie] = useCookies(['loginToken']);
  // const hubConnection = useSelector((state) => state.main.hubConnection);

  function handleExpand(currendAccordion) {
    currendAccordion === expanded ? setExpanded('') : setExpanded(currendAccordion);
  }

  useEffect(() => {

    setProfileMenuList([
      {
        page: 'profile',
        title: t('myInformation')
      },
      {
        page: 'password',
        title: t('profileChangePassword')
      },
      {
        page: 'subscriptions',
        title: t('subscriptions')
      },
      {
        page: 'pastMeetings',
        title: t('myPastMeeting')
      }
    ]);
  }, [i18n.language]);

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        onClick={() => onClose()}
      >
        <IconButton>
          <CloseIcon fontSize="small"/>
        </IconButton>
      </Box>

      {isAuthenticated &&
        <Box sx={{paddingBottom: 1}} onClick={() => {
          history.push('/profile/meetings');
          onClose();
        }}>
          <Accordion sx={{backgroundColor: 'transparent', color: '#53B2FF'}} expanded={false}>
            <AccordionSummary
              sx={{
                padding: '0px 10px 0px 20px'
              }}
            >
              <EventAvailableIcon/>
              <Typography marginLeft={1}>{t('meetings')}</Typography>
            </AccordionSummary>
          </Accordion>
        </Box>
      }

      <Box sx={{paddingBottom: 1}} onClick={() => {
        history.push('/pricing');
        onClose();
      }}>
        <Accordion sx={{backgroundColor: 'transparent', color: '#53B2FF'}} expanded={false}>
          <AccordionSummary
            sx={{
              padding: '0px 10px 0px 20px'
            }}
          >
            <ShoppingBasketIcon/>
            <Typography marginLeft={1}>{t('buyNow')}</Typography>
          </AccordionSummary>
        </Accordion>
      </Box>

      <Box sx={{paddingBottom: 1}} onClick={() => {
        history.push('/contact-us');
        onClose();
      }}>
        <Accordion sx={{backgroundColor: 'transparent', color: '#53B2FF'}} expanded={false}>
          <AccordionSummary
            sx={{
              padding: '0px 10px 0px 20px'
            }}
          >
            <SupportAgentIcon/>
            <Typography marginLeft={1}>{t('contactUs')}</Typography>
          </AccordionSummary>
        </Accordion>
      </Box>

      {isAuthenticated &&
        <Box sx={{paddingBottom: 1}}>
          <Accordion
            sx={{backgroundColor: 'transparent', color: '#53B2FF'}} expanded={expanded === 'profile'}
            onClick={() => handleExpand('profile')}>
            <AccordionSummary
              sx={{
                padding: '0px 10px 0px 20px'
              }}
              expandIcon={<ExpandMoreIcon sx={{transform: 'scale(1)', color: '#53B2FF'}}/>}
            >
              <PersonIcon/>
              <Typography marginLeft={1}>{t('profile')}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{paddingBottom: 0}}>
              <List component="nav" sx={{width: '100% !important'}}>
                {
                  profileMenuList.map((item, i) => (
                    <Box key={i}>
                      <ListItem
                        // component={Link}
                        // href={'/profile/' + item.page}
                        button
                        onClick={() => {
                          history.push('/profile/' + item.page);
                          onClose();
                        }}
                      >
                        <Box
                          width={30}
                          height={30}
                          color={'#ECEBEE'}
                        >
                          {item.icon}
                        </Box>
                        <ListItemText primary={item.title}/>
                      </ListItem>
                      {i !== profileMenuList.length - 1 &&
                        <Divider/>
                      }
                    </Box>
                  ))
                }
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
      }

      <Box sx={{paddingBottom: 1}}>
        <Accordion
          sx={{backgroundColor: 'transparent', color: '#53B2FF'}} expanded={expanded == 'language'}
          onClick={() => handleExpand('language')}>
          <AccordionSummary
            sx={{
              padding: '0px 10px 0px 20px'
            }}
            expandIcon={<ExpandMoreIcon sx={{transform: 'scale(1)', color: '#53B2FF'}}/>}
          >
            <LanguageIcon/>
            <Typography marginLeft={1}>{t('headerLanguage')}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{paddingBottom: 0}}>

            <List component="nav" sx={{width: '100% !important'}}>
              {
                LangList.map((item, i) => (
                  <Box key={i}>
                    <ListItem
                      button
                      onClick={() => {
                        i18n.changeLanguage(item.page);
                        localStorage.setItem('lngChnged', true);
                        onClose();
                      }}
                    >
                      <Box
                        // component={item.icon}
                        width={30}
                        height={20}
                        margin={0}
                        color={'#53B2FF'}
                        variant="square"
                      >
                        {item.icon}
                      </Box>
                      <ListItemText primary={item.title}/>
                    </ListItem>

                  </Box>
                ))
              }
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box sx={{paddingBottom: 1}}>
      <Button
          onClick={() => {
            if (isAuthenticated) {
              // hubConnection.stop();
              handleSignOut(dispatch, removeCookie, history);
              onClose();
              history.push('/login');
            } else {
              history.push('/login');
              onClose();
            }
          }}
          variant="contained"
          fullWidth
          sx={{ backgroundColor: '#53B2FF', color: '#fff'}}>
        {isAuthenticated ? <LogoutIcon sx={{marginRight: 1}}/> : <LoginIcon sx={{marginRight: 1}}/>}
        {isAuthenticated ? t('logout') : t('accountSignIn')}
      </Button>
      </Box>

    </Box>
  );
};

SidebarNav.propTypes = {
  onClose: PropTypes.func
};

export default SidebarNav;
