import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {Grid} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import personIcon from '../../../../../svg/icons/person.svg';
import {useDispatch, useSelector} from 'react-redux';
import {setOutGoingCall, setOutGoingUserName} from '../../../../../redux/actions';
import TooltipButton from '../../../../../layouts/Main/components/ButtonWithTooltip/TooltipButton';

const Persons = () => {
  const theme = useTheme();
  const {t} = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const loginUser = useSelector((state) => state.main.user);
  let onlineUsers = useSelector((state) => state.main.onlineUsers);
  const isXS = useMediaQuery(theme.breakpoints.up('xs'), {defaultMatches: true});
  const hubConnection = useSelector((state) => state.main.hubConnection);

  function callUser(user) {
    hubConnection.invoke('CallToUser', user.email).then((callID) => {
      let outGoingCallModel = {userName: user.fullName, callID: callID};
      dispatch(setOutGoingCall(outGoingCallModel));
      dispatch(setOutGoingUserName(user.fullName));
    });
  }

  return (
    <Box>
      <Box marginBottom={7}>
        <Grid
          container
        >
          <Grid
            item
            md={8}
            xs={12}
          >
            <Typography
              variant="h5"
              color="text.tertiary"
              sx={{
                fontWeight: 700,
                textAlign: 'start'
              }}
            >
              {t('persons')}
            </Typography>
            <small>
              {t('thisListOnlyOnlineUsers')}
            </small>
          </Grid>

          <Grid
            item
            md={4}
            container
            sx={{justifyContent: 'end'}}
          >
            <TextField
              fullWidth={isXS}
              label={t('search')}
              size={'small'}
              sx={{float: 'right', width: {xs: '%100', md: 240}, mr: 1, mt: {xs: 3, md: 0}}}
              onInput={() => {
                // const value = e.target.value;
                // setSearch(value);
              }}
            />
          </Grid>
        </Grid>

        <Grid container sx={{marginTop: 5, borderTop: '1px solid rgba(58,21,161,50%)'}}>
          {onlineUsers.map((item, i) => {
            return (
              <Grid
                key={i}
                container
                direction={'row'}
                sx={{
                  paddingTop: 2,
                  paddingBottom: 2,
                  borderBottom: '1px solid rgba(58,21,161,50%)',
                }}
              >
                <Grid item md={9} style={{display: 'flex'}}>
                  <Box component={'div'} style={{width: '50px', height: '50px'}}>
                    <img
                      style={{
                        backgroundColor: item.status == 1 ? 'red' : 'white',
                        padding: item.status == 1 ? '2px' : '0px',
                        borderRadius: item.status == 1 ? '30px' : '0px',
                      }}
                      src={personIcon}
                      width={50}
                      height={50}
                    />
                  </Box>
                  <Box flex={1} component={'div'} marginLeft={'10px'} paddingTop={'4px'}>
                    <div>
                      <strong>{item.fullName}</strong>
                    </div>
                    <small style={{color: '#9D9D9D', lineHeight: '12px', display: 'block'}}>{item.email}</small>
                  </Box>
                </Grid>
                <Grid
                  item
                  md={3}
                  textAlign={'right'}
                >
                  {/*<Tooltip*/}
                  {/*  title={'Kendinizi arayamazsınız'}>*/}
                  {/*  <Button*/}
                  {/*    disabled={item.email == loginUser.email}*/}
                  {/*    onClick={() => callUser(item)}*/}
                  {/*    color={'primary'} style={{borderRadius: '50%', width: '45px', height: '45px', minWidth: '45px'}}*/}
                  {/*    variant={'contained'}*/}
                  {/*  >*/}
                  {/*  </Button>*/}
                  {/*</Tooltip>*/}
                  <TooltipButton
                    showTooltipWhenDisabled={item.email == loginUser.email}
                    tooltipText={item.email == loginUser.email ? 'Kendinizi Arayamazsınız' : item.status == 1 ? 'Kullanıcı Şuan Başka Bir Görüşmede' : ''}
                    disabled={item.email == loginUser.email || item.status == 1}
                    onClick={() => callUser(item)}
                    color={'primary'} style={{borderRadius: '50%', width: '45px', height: '45px', minWidth: '45px'}}
                    variant={'contained'}
                  >
                    <PhoneIcon/>
                  </TooltipButton>

                </Grid>
              </Grid>
            );
          })}

          {onlineUsers.length == 0 &&
            <Grid
              container
              direction={'row'}
              sx={{
                paddingTop: 2,
                paddingBottom: 2,
                borderBottom: '1px solid rgba(58,21,161,50%)',
                justifyContent: 'center'
              }}
            >
              {t('noOnlineUsers')}
            </Grid>
          }
        </Grid>
      </Box>
    </Box>
  );
};

export default Persons;
