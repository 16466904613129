import {Cookies, Request} from './index';
import store from '../redux/store';
import {setContainerLoader} from 'redux/actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);


export default {
  get: (url, callback, errorCallback) => {
    Request.get(url, {
      headers: getHeaderInformation()
    }).then(function (response) {
      handleResponse(response, callback);
    }).catch(function (error) {
      handleResponse(error.response, errorCallback);
    });
  },
  getBlobFile: (url, callback, errorCallback) => {
    Request.get(url, {
      responseType: 'blob',
      headers: getHeaderInformation()
    }).then(function (response) {
      handleResponse(response, callback);
    }).catch(function (error) {
      handleResponse(error.response, errorCallback);
    });
  },
  post: (url, data, callback, errorCallback) => {
    let axiosConfig = {
      headers: getHeaderInformation()
    };
    Request.post(url, data, axiosConfig)
      .then(function (response) {
        handleResponse(response, callback);
      })
      .catch(function (error) {
        handleResponse(error.response, errorCallback);
      });
  },
  postMultiPart: (url, data, callback, errorCallback) => {
    let axiosConfig = {
      headers: getHeaderInformation()
    };
    axiosConfig.headers['Content-Type'] = 'multipart/form-data';
    Request.post(url, data, axiosConfig)
      .then(function (response) {
        handleResponse(response, callback);
      })
      .catch(function (error) {
        handleResponse(error.response, errorCallback);
      });
  },
  postPromise: (url, data) => {
    let axiosConfig = {
      headers: getHeaderInformation()
    };
    return Request.post(url, data, axiosConfig).catch(function (error) {
      handleResponse(error.response);
    });
  },
  getPromise: (url) => {
    return Request.get(url, {
      headers: getHeaderInformation()
    }).catch(function (error) {
      handleResponse(error.response);
    });
  }
};

function handleResponse(response, callback) {
  // eslint-disable-next-line no-undef
  let language = require('../locales/' + (localStorage.getItem('i18nextLng') != null ? localStorage.getItem('i18nextLng') : 'tr') + '.json');
  if (!response) {
    store.dispatch(setContainerLoader(false));
    MySwal.fire({
      icon: 'error',
      title:  language.error,
      text: language.cantResponseServer
    });
    return;
  }
  if (Object.prototype.hasOwnProperty.call(response.headers, 'exception')) {
    if (response.headers.exception === 'DomainException') {
      if (response.status === 403) {
        MySwal.fire({
          icon: 'error',
          title: language.error,
          html: response.data.content,
          confirmButtonText: language.ok
        });
        store.dispatch(setContainerLoader(false));
      } else if (response.status === 401) {
        window.document.cookie = '';
        window.sessionStorage.clear();
        window.localStorage.removeItem('persist:root');
        store.dispatch({type: 'LOG_OUT'});
        if (response.request.responseURL.includes('Account/login')){
          MySwal.fire({
            icon: 'error',
            title: language.error,
            html: response.data.content,
            confirmButtonText: language.ok
          });
          store.dispatch(setContainerLoader(false));
        } else {
          window.location.href = '/login';
        }
      } else if (response.status === 500) {
        if (response.data.content) {
          MySwal.fire({
            icon: 'error',
            title: language.error,
            html: language.error500Info
          });
          store.dispatch(setContainerLoader(false));
        } else {
          MySwal.fire({
            icon: 'error',
            title: language.error,
            html: language.error500Info
          });
          store.dispatch(setContainerLoader(false));
        }
        if (callback) {
          callback(response.data);
        }
      } else if (response.data.content) {
        MySwal.fire({
          icon: 'error',
          title: language.error,
          html: response.data.content,
          confirmButtonText: language.ok
        });
        store.dispatch(setContainerLoader(false));
      } else {
        store.dispatch(setContainerLoader(false));
        MySwal.fire({
          icon: 'error',
          title: language.error,
          text: language.error500Info
        });
      }
      if (callback) {
        callback(response.data);
      }
    } else {
      store.dispatch(setContainerLoader(false));
      // useErrorModal(response.data);
      if (callback) {
        callback(response.data);
      }
      if (response.status === 401) {
        window.document.cookie = '';
        window.sessionStorage.clear();
        window.localStorage.removeItem('persist:root');
        store.dispatch({type: 'LOG_OUT'});
      }
    }
    return;
  }
  if (response.status === 200) {
    if (callback) {
      callback(response.data);
    }
  } else if (response.status === 401) {
    window.document.cookie = '';
    window.sessionStorage.clear();
    window.localStorage.removeItem('persist:root');
    store.dispatch({type: 'LOG_OUT'});
    window.location.href = '/login';
  } else if (response.status === 403) {
    MySwal.fire({
      icon: 'error',
      title: language.error,
      html: response.data.content,
      confirmButtonText: language.ok
    });
    store.dispatch(setContainerLoader(false));
  } else if (response.status === 201) {
    MySwal.fire({
      icon: 'error',
      title: language.error,
      html: response.data.content
    });
    if (callback) {
      callback(response.data);
    }
  } else if (response.status === 202) {
    MySwal.fire({
      icon: 'error',
      title: language.error,
      html: response.data.content
    });
    if (callback) {
      callback(response.data);
    }
  } else if (response.status === 400) {
    if (response.data.content) {
      MySwal.fire({
        icon: 'error',
        title: language.error,
        html: response.data.content
      });
      store.dispatch(setContainerLoader(false));
    } else {
      MySwal.fire({
        icon: 'error',
        title: language.error,
        text: Object.values(response.data.errors)[0][0]
      });
      store.dispatch(setContainerLoader(false));
    }
    if (callback) {
      callback(response.data);
    }
  } else if (response.status === 404) {
    if (response.data.content) {
      MySwal.fire({
        icon: 'error',
        title: language.error,
        html: response.data.content
      });
      store.dispatch(setContainerLoader(false));
    } else {
      MySwal.fire({
        icon: 'error',
        title: language.error,
        text: Object.values(response?.data?.errors)[0][0]
      });
      store.dispatch(setContainerLoader(false));
    }
    if (callback) {
      callback(response.data);
    }
  } else if (response.status === 413) {
    if (response.data.content) {
      MySwal.fire({
        icon: 'error',
        title: language.error,
        html: response.data.content
      });
      store.dispatch(setContainerLoader(false));
    } else {
      MySwal.fire({
        icon: 'error',
        title: language.error,
        html: response.data.content
      });
      store.dispatch(setContainerLoader(false));
    }
    if (callback) {
      callback(response.data);
    }
  } else if (response.status === 500) {
    if (response.data.content) {
      MySwal.fire({
        icon: 'error',
        title: language.error,
        text: language.error500Info
      });
      store.dispatch(setContainerLoader(false));
    } else {
      MySwal.fire({
        icon: 'error',
        title: language.error,
        text: language.error500Info
      });
      store.dispatch(setContainerLoader(false));
    }
    if (callback) {
      callback(response.data);
    }
  } else {
    if (response.data.content) {
      MySwal.fire({
        icon: 'error',
        title: language.error,
        html: response.data.content
      });
      store.dispatch(setContainerLoader(false));
    } else {
      MySwal.fire({
        icon: 'error',
        title: language.error,
        text: language.error500Info
      });
      store.dispatch(setContainerLoader(false));
    }

    if (callback) {
      callback(response.data);
    }
  }
}

function getHeaderInformation() {
  let jwt = Cookies.getToken();
  if (jwt) {
    return {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'Authorization': `Bearer ${jwt}`,
      'client-lang': localStorage.getItem('i18nextLng') || 'en'
    };
  } else {
    return {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'client-lang': localStorage.getItem('i18nextLng')  || 'en'
    };
  }
}
