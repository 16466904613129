import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
//import { useTheme } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.node.isRequired,
};

const BodyMain = ({ children }) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    setDefaultLang();
  }, []);

  function setDefaultLang() {
    if (localStorage.getItem('lngChnged') !== 'true') {
      localStorage.setItem('i18nextLng', 'tr');
    }
  }

  return (
    <main>
      <Box>{children}</Box>
    </main>
  );
};

BodyMain.propTypes = {
  children: PropTypes.node,
};

export default BodyMain;
