import React from 'react';
import { Box, List, ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AR from 'svg/flag/ar.png';
import TR from 'svg/flag/tr.png';
import RU from 'svg/flag/ru.png';
import EN from 'svg/flag/en.png';

const languages = [
  { code: 'tr' },
  { code: 'en' }, 
  { code: 'ar' },
  { code: 'ru' }
];

const LanguageSwitcher = () => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();

  function changeLang (lang) {
    i18n.changeLanguage(lang);
    localStorage.setItem('lngChnged', true);
  }

  const options = languages.map((language, i) => {
    if (language.code != i18n.language) {
      return <ListItem
        sx={{ paddingLeft: '7px', paddingRight: '7px',   textAlign:'center', width:'100%', justifyContent:'center', paddingTop:0, paddingBottom:0 }}
        key={i}
        onClick={() => changeLang(language.code)}>
        <Box>
          <img src={flagger(language.code.toLowerCase())} alt="" style={{borderRadius:'100%'}} width={35}/>
        </Box>
      </ListItem>;
    }
  });

  function flagger (lang) {
    switch (lang) {
      case 'tr':
        return TR;
      case 'en':
        return EN;
      case 'ru':
        return RU;
      case 'ar':
        return AR;
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        cursor: 'pointer',
        '& .MuiBox-root.MuiBox-root': {
          display: 'block'
        },
        '& .MuiList-root': {
           transition:'all 1s ease-in-out'
        },
        '&:hover': {
          '& .MuiList-root': {
            opacity: 1,
            visibility:'visible',
          },
        }

      }}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        className="targetBox"
        sx={{
          backgroundColor: '#F0F5FF',
          border: '2px solid #F0F5FF',
          borderRadius: '10px !important',
          fontSize: '14px',  
          color:'#53B2FF',
          position:'relative',
          fontWeight:'700',
          paddingLeft:'3px',
          '&:after': {
            content:'""',
            position:'absolute',
            width:'7px',
            height:'7px',
            right:'8px',
            top:'19px',
            zIndex:1,
            borderRight:'2px solid #30336B',
            borderBottom:'2px solid #30336B',
            transform: 'rotate(45deg)'
          }
        }}
         
      >
        <Box width={60} height={50} alignItems={'center'} style={{display:'flex',justifyContent:i18n.language === 'ar'?'left':null}}>
          <img src={flagger(i18n.language.toLowerCase())} alt="" width={40}/>
        </Box>
        <Box></Box>
      </Box>
      <List sx={{
        backgroundColor: '#F0F5FF !important',
        border: '2px solid #F0F5FF',
        top: '50px !important',
        borderBottomLeftRadius: '10px !important',
        borderBottomRightRadius: '10px !important',
        position: 'absolute',
        opacity: 0,
        visibility:'hidden',
        margin: '0',
        padding: '0',
        listStyle: 'none',
        fontSize: '14px', 
        color:'#30336B', 
        fontWeight:'700',
        width:'55px',
        '&:after': {
          content:'""',
          position:'absolute',
          left:'0px',
          height:'8px',
          right:'0px',
          top:'-10px',
          zIndex:1,
        }
      }}>
        {options}
      </List>
    </Box>
  );

};

export default LanguageSwitcher;
