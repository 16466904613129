/* eslint-disable react/no-unescaped-entities */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AccountServices } from 'services/index';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { JSEncrypt } from 'jsencrypt';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

const Form = () => {
  let history = useHistory();
  let location = useLocation();
  const { t } = useTranslation();
  const initialValues = {
    newPassword: '',
    repeatNewPassword: '',
    verificationCode: ''
  };

  const validationSchema = yup.object({
    newPassword: yup
      .string()
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/, t('validPasswordRegex'))
      .required(t('requiredField'))
      .min(8, t('validPasswordMin')),
    repeatNewPassword: yup.string().required(t('requiredField'))
      .oneOf([yup.ref('newPassword'), null], t('registerCheckPassword'))
  });

  const onSubmit = (values) => {
    let sentValues = { ...values };
    sentValues.userId = 0;
    sentValues.verificationCode = location.search.substring(3, location.search.length);
    changePass(sentValues);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit
  });

  function changePass (data) {

    data.isPublic = true;

    var encrypt = new JSEncrypt();
    encrypt.setPublicKey('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDGy8btrbnSNPz7vWKfQXKxKXzg28ZD8jCAd7gGYfUIFqKqUcogHWt5gyGvTgEhwBwBP1kYrVnBlhB2nuWHLYpJDI6buBoqKrHtrcdgXsKumSP0OKpn0nbYxknOvNYVjUUR6plMboUBaWX1oKoR6pNzTEHSal4bIU7XMwppkR3KNQIDAQAB');
    data.newPassword = encrypt.encrypt(data.newPassword);
    data.repeatNewPassword = encrypt.encrypt(data.repeatNewPassword);

    var promise = AccountServices.changePassword(data);

    promise.then((response) => {
      if (response) {
        let timerInterval;
        Swal.fire({
          title: t('success'),
          timer: 2500,
          timerProgressBar: true,
          showCloseButton: false,
          showConfirmButton: false,
          showDenyButton: false,
          text: t('changePasswordSuccessMessage'),
          willClose: () => {
            clearInterval(timerInterval);
          }
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            history.push('/login');
          }
        });
      }
    }).catch((e) => {
      console.log(e);
    });
  }

  return (
    <Box marginBottom={15} marginRight={7} marginLeft={7}>
      <Box marginBottom={4}>
        <Typography
          variant="h5"
          color="text.tertiary"
          textAlign={'center'}
          sx={{
            fontWeight: 700,
            marginBottom: 2
          }}
        >
          {t('passwordChange')}
        </Typography>
        <center>
          <small>
            {t('passwordChangeMessage')}
          </small>
        </center>

      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Tooltip title={<div style={{ padding: 5 }}>
              {t('registerValidationMessage1')}
              <br/>
              {t('registerValidationMessage2')}
              <br/>
              {t('registerValidationMessage3')}
              <br/>
              {t('registerValidationMessage4')}
            </div>} placement={'bottom-start'}>
              <TextField
                autoComplete={'false'}
                label={t('accountNewPassword')}
                variant="outlined"
                name={'newPassword'}
                type={'password'}
                fullWidth
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                helperText={formik.touched.newPassword && formik.errors.newPassword}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete={'false'}
              label={t('accountRepassword')}
              variant="outlined"
              name={'repeatNewPassword'}
              type={'password'}
              fullWidth
              value={formik.values.repeatNewPassword}
              onChange={formik.handleChange}
              error={formik.touched.repeatNewPassword && Boolean(formik.errors.repeatNewPassword)}
              helperText={formik.touched.repeatNewPassword && formik.errors.repeatNewPassword}
            />
          </Grid>

          <Grid style={{ paddingTop: '30px' }} container xs={12} justifyContent={'center'}>
            <Grid item md={12} xs={12} textAlign={'center'}>
              <Button
                onClick={() => history.push('/login')}
                variant="contained"
                color="secondary"
                size="large"
                sx={{
                  marginRight: 2,
                  color: '#53B2FF',
                  fontWeight: 700,
                  minWidth: '100px',
                  width: '40%',
                  maxWidth: '160px'
                }}
              >
                {t('cancel')}
              </Button>
              <Button
                size={'large'}
                variant={'contained'}
                type={'submit'}
                sx={{ minWidth: '100px', width: '40%', maxWidth: '160px' }}
              >
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
