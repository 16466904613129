import React, {useState} from 'react';
import Box from '@mui/material/Box';
import {Avatar, Button, Modal} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {AiOutlinePlus} from 'react-icons/ai';
import {BsFillTrash3Fill} from 'react-icons/bs';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


const AvatarChange = () => {
	const {t, i18n} = useTranslation();
	// eslint-disable-next-line no-unused-vars
	const [open, setOpen] = useState(false);
	// const user = useSelector((state) => state.main.user);
	return (
		<Box>
			<Button
				size={'large'}
				variant={'contained'}
				type={'submit'}
				onClick={() => {
					setOpen(true);
				}}
				sx={{
					minWidth: '100px',
					padding: {xs: '5px', md: '11px'},
					width: '100%',
					backgroundColor: '#F0F5FF',
					boxShadow: 'none',
					'&:hover': {backgroundColor: '#F0F5FF', boxShadow: 'none'},
					color: '#424242',
					borderRadius: '10px'
				}}>
				<Box component={'span'} sx={{display: {xs: 'none', md: 'inline'}}}>Profil</Box> Fotoğrafı Düzenle
			</Button>
			
			<Modal open={open ? true : false} onClose={() => {
				setOpen(false);
			}}>
				<Box style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					maxWidth: '407px',
					maxHeight: '380px',
					width: '100%',
					minHeight: '380px',
					backgroundColor: '#fff',
					border: '0px solid #000',
					borderRadius: '15px',
					boxShadow: 24,
					padding: '60px 20px 20px 20px',
					paddingBottom: 5,
					overflow: 'auto',
					'&::-webkit-scrollbar': {
						width: '0px'
					}
				}}>
					<a style={{
						backgroundColor: '#F0F5FF',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '100%',
						width: '34px',
						height: '34px',
						position: 'absolute', right: '20px', top: '20px'
					}} onClick={() => {
						setOpen(false);
					}}>
						<CloseRoundedIcon/>
					</a>
					<Box style={{width: '305px', margin: '0 auto'}}>
						<Box marginBottom={3}>
							<Typography
								variant="h5"
								color="text.tertiary"
								sx={{
									fontWeight: 700,
									textAlign: 'start'
								}}
							>
								Profil Fotoğrafını Değiştir
							</Typography>
						</Box>
						<Box
							display={'flex'}
							minWidth={185}
							padding={'8px'}
							className="targetBox"
							sx={{
								alignItems: 'center',
								borderRadius: '10px !important',
								fontSize: '0.875rem',
								direction: i18n.language === 'ar' ? 'initial' : null
							}}
						>
							<Box sx={{
								borderRadius: '5px !important'
							}}>
								
								<Avatar src="/broken-image.jpg" sx={{height: 122, width: 122, borderRadius: '10px'}}/>
							</Box>
							<Box paddingLeft={'30px'} flex={1} overflow={'hidden'} sx={{
								position: 'relative'
							}}>
								<div style={{
									color: '#424242',
									position: 'relative',
									fontWeight: 500
								}}>
									<div style={{fontSize: '17px', marginBottom: '2px'}}>
										<Box>
											<Box component={'a'} style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
												<AiOutlinePlus/>
												<span style={{marginLeft: '7px', fontSize: '15px'}}>Fotoğraf yükle</span></Box>
										</Box>
										<Box>
											<Box component={'a'} style={{display: 'flex', alignItems: 'center'}}>
												<BsFillTrash3Fill/>
												<span style={{marginLeft: '7px', fontSize: '15px'}}>Fotoğraf Kaldır</span></Box>
										</Box>
									</div>
								</div>
							</Box>
						</Box>
						<Box style={{marginTop: '30px'}}>
							<Button
								size={'large'}
								variant={'contained'}
								type={'submit'}
								sx={{minWidth: '100px', width: '100%', borderRadius: '10px', padding: '10px'}}>
								{t('save')}
							</Button>
						</Box>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
};

export default AvatarChange;
