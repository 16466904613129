/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useCookies } from 'react-cookie';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Modal } from '@mui/material';
import { AccountServices } from 'services/index';
import loginTypeEnum from 'enums/loginTypeEnum';
// import {ReactComponent as googleIcon} from 'svg/misc/googleIcon.svg';
// import GoogleLogin from 'react-google-login';
import ForgotPasswordSimple from 'views/authPages/ForgotPasswordSimple';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { handleAfterLoginStoreWorks, handleAfterLoginTokenWorks } from 'utils/loginHelpers';
import { useHistory, useLocation } from 'react-router-dom';
import { JSEncrypt } from 'jsencrypt';
import { useTranslation } from 'react-i18next';
import ChangePassword from '../../../../supportingPages/Profile/components/ChangePassword/ChangePassword';
import { LoadingButton } from '@mui/lab';
// import ReCAPTCHA from 'react-google-recaptcha';
// import InfoIcon from '@mui/icons-material/Info';

const Form = () => {
  let history = useHistory();
  const { t, i18n } = useTranslation();
  let location = useLocation();
  // const recaptchaRef = useRef();
  const [show, setShow] = useState(false);
  // const [reCaptchaLoad, setReCaptchaLoad] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['token']);
  const [mustChangePassword, setMustChangePassword] = useState(false);

  const validationSchema = yup.object({
    email: yup
      .string()
      .trim()
      .email(t('validEmail'))
      .matches(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, t('validEmail'))
      .emailSyntaxCheck(t('validEmail'))
      .required(t('requiredField')),
    password: yup
      .string()
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/, t('validPasswordRegex'))
      .required(t('validPassword'))
      .min(8, t('validPasswordMin'))
  });

  const initialValues = {
    email: '',
    password: '',
    recaptcha: ''
  };

  useEffect(() => {
    formik.resetForm();
    setTimeout(() => {
      // setReCaptchaLoad(true);
    }, 1500);
    return () => {
      formik.resetForm();
    };
  }, []);

  // useEffect(() => {
  //   setReCaptchaLoad(false);
  //   setTimeout(() => {
  //     setReCaptchaLoad(true);
  //   }, 1500);
  // }, [i18n.language]);

  const onSubmit = (values) => {
    console.log(values);
    setIsLoading(true);
    let sentValues = { ...values };
    sentValues.loginType = loginTypeEnum.StandAlone;
    signIn(sentValues);
    return;
  };

  const signIn = (data) => {
    console.log(data);
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDGy8btrbnSNPz7vWKfQXKxKXzg28ZD8jCAd7gGYfUIFqKqUcogHWt5gyGvTgEhwBwBP1kYrVnBlhB2nuWHLYpJDI6buBoqKrHtrcdgXsKumSP0OKpn0nbYxknOvNYVjUUR6plMboUBaWX1oKoR6pNzTEHSal4bIU7XMwppkR3KNQIDAQAB');
    data.password = encrypt.encrypt(data.password);
    delete data.recaptcha;
    var promise = AccountServices.login(data);
    promise
      .then((response) => {
        handleAfterLoginTokenWorks(response.data.token, setCookie);
        handleAfterLoginStoreWorks();
        if (response.data.mustChangePasswordNextLogon) {
          setMustChangePassword(response.data.mustChangePasswordNextLogon);
        } else {
          redirect();
        }
      })
      .catch(() => {
        data.password = '';
        setIsLoading(false);
      });
  };

  const redirect = () => {
    const query = new URLSearchParams(location.search);
    const q = query.get('redirectUrl');
    if (q) {
      setTimeout(() => {
        history.push(q);
      }, 1000);
    } else {
      history.push('/');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit
  });

  // const signInGoogle = (googleUser) => {
  //   var secondKey = Object.keys(googleUser)[1];
  //
  //   if (process.env.NODE_ENV !== 'development') {
  //     AccountServices.googleLogin({googleId: googleUser[secondKey].id_token}).then((response) => {
  //       if (response && response.data) {
  //         handleAfterLoginTokenWorks(response.data.token, setCookie);
  //         handleAfterLoginStoreWorks();
  //         redirect();
  //         setIsLoading(false);
  //       }
  //     });
  //   }
  // };

  return (
    <Box paddingBottom={3} sx={{ padding: { xs: '0 10px 0 10px', md: '0' } }}>
      <Box marginBottom={4} textAlign={'left'}>
        <Typography
          variant="h5"
          color="text.tertiary"
          sx={{
            fontWeight: 700
          }}
        >
          {t('accountSignIn')}
        </Typography>
        <Box sx={{ fontSize: { xs: '12px', md: '14px' }, marginTop: '5px' }}>
          <span style={{ color: '#424242', fontSize: '14px', fontWeight: 400 }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras quis lobortis nulla.
        </span>
        </Box>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Grid
          container spacing={4} direction="column"
          alignItems={{ xs: 'stretched', sm: 'stretched' }}
          justifyContent="center"
        >
          <Grid item xs={12} md={6}>
            <TextField
              label={t('emailAdressRequired')}
              variant="outlined"
              name={'email'}
              fullWidth
              className={'border-none'}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              inputProps={{ style: { textTransform: 'lowercase', borderRadius: '10px' } }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('passwordRequired')}
              variant="outlined"
              name={'password'}
              type={'password'}
              className={'border-none'}
              style={{ borderRadius: '10px !important' }}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              inputProps={{ style: { borderRadius: '10px' } }}
            />
            <Box
              display="flex"
              flexDirection={{ xs: 'row', sm: 'row' }}
              alignItems={{ xs: 'center', sm: 'center' }}
              justifyContent={'space-between'}
              width={'100%'}
              marginY={1}
            >
              {/*<FormControlLabel*/}
              {/*  control={<Checkbox defaultunchecked="true"/>}*/}
              {/*  label={t('rememberMe')}*/}
              {/*  sx={{*/}
              {/*    '& span': {*/}
              {/*      fontSize: '0.875em'*/}
              {/*    }*/}
              {/*  }}*/}
              {/*/>*/}
              <Typography variant={'subtitle2'}>
                <Link
                  component={Button}
                  color={'primary'}
                  onClick={handleShow}
                  underline={'none'}
                >
                  {t('forgotPassword')}
                </Link>
                <Modal
                  open={show}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box position={'relative'}
                       backgroundColor={'white'}
                       maxWidth={600}
                       minWidth={'30%'}
                       sx={{
                         borderRadius: '20px',
                         position: 'absolute',
                         left: { xs: '10%', md: '50%' },
                         top: { xs: '25%', md: '50%' },
                         transform: { xs: 'translate(-5%, -25%)', md: 'translate(-50%, -50%)' },
                         padding: '50px 0 50px 0'
                       }}
                  >
                    <Box sx={{ position: 'absolute', right: '0px', top: '15px' }}>
                      <Button onClick={handleClose}><CloseRoundedIcon/></Button>
                    </Box>
                    <ForgotPasswordSimple/>
                  </Box>
                </Modal>
              </Typography>
            </Box>
          </Grid>

          {/*<Grid item container xs={12} md={6} justifyContent={'center'}>*/}
          {/*  {*/}
          {/*    reCaptchaLoad && <>*/}
          {/*      <ReCAPTCHA*/}
          {/*        ref={recaptchaRef}*/}
          {/*        hl={i18n.language}*/}
          {/*        sitekey={process.env.REACT_APP_SITE_KEY}*/}
          {/*        onChange={(value) => {*/}
          {/*          formik.setFieldValue('recaptcha', value);*/}
          {/*          formik.setSubmitting(false);*/}
          {/*        }}*/}
          {/*        size="normal"*/}
          {/*      />*/}
          {/*      {*/}
          {/*        (formik && formik.touched && formik.touched.recaptcha && formik.errors.recaptcha) ? <p style={{*/}
          {/*          fontSize: '0.75rem',*/}
          {/*          lineHeight: '1.66',*/}
          {/*          marginTop: '3px',*/}
          {/*          color: '#d32f2f'*/}
          {/*        }}>*/}
          {/*          <InfoIcon*/}
          {/*            style={{*/}
          {/*              width: '17px',*/}
          {/*              height: '17px',*/}
          {/*              marginRight: '5px',*/}
          {/*              verticalAlign: 'middle'*/}
          {/*            }}/>*/}
          {/*          <span style={{lineHeight: '17px', verticalAlign: 'middle'}}>{formik.errors.recaptcha}</span>*/}
          {/*        </p> : null*/}
          {/*      }*/}
          {/*    </>*/}
          {/*  }*/}
          {/*</Grid>*/}

          <Grid item container xs={12} md={6}>
            <Box
              display="block"
              style={{ width: '100%' }}
            >
              <LoadingButton
                loading={isLoading}
                color={'primary'}
                size={'large'}
                variant={'contained'}
                type={'submit'}
                sx={{
                  fontWeight: 500,
                  padding: '13px',
                  width: '100%',
                  borderRadius: '10px',
                  backgroundColor: '#53B2FF'
                }}
              >
                {t('headerSignIn')}
              </LoadingButton>

            </Box>
          </Grid>
          <Grid item container xs={12} md={6} sx={{ paddingTop: '10px !important', textAlign: 'center' }}
                justifyContent={'center'}>
            <Typography variant={'subtitle2'}>
              <Link
                component={Button}
                color={'primary'}
                onClick={() => {history.replace('/signup');}}
                underline={'none'}
              >
                {t('newRegist')}
              </Link>
            </Typography>
          </Grid>

          {/*<Box paddingLeft={4} paddingTop={4}>*/}
          {/*  <Divider*/}
          {/*    textAlign="center"*/}
          {/*    paddingleft={'32px !important'}*/}
          {/*    sx={{color: 'text.tertiary', fontSize: '0.875rem', fontWeight: 500}}*/}
          {/*  >*/}
          {/*    {t('stickyOr')}*/}
          {/*  </Divider>*/}
          {/*</Box>*/}
          {/*<Grid item container xs={12} md={6} justifyContent={'center'}>*/}
          {/*  <Box*/}
          {/*    display="flex"*/}
          {/*    flexDirection={{xs: 'column', sm: 'column'}}*/}
          {/*    alignContent={'space-between'}*/}
          {/*    justifyContent={'space-between'}*/}
          {/*    marginBottom={{xs: 10, md: 0}}*/}
          {/*  >*/}
          {/*    <GoogleLogin*/}
          {/*      clientId="668446358673-h457udhcr22diodkrfamtuae8kg74p32.apps.googleusercontent.com"*/}
          {/*      buttonText="Login"*/}
          {/*      onSuccess={signInGoogle}*/}
          {/*      onFailure={signInGoogle}*/}
          {/*      cookiePolicy={'single_host_origin'}*/}
          {/*      render={renderProps => (*/}
          {/*        <Button*/}
          {/*          onClick={renderProps.onClick}*/}
          {/*          disabled={renderProps.disabled}*/}
          {/*          variant="contained"*/}
          {/*          color={'google'} sx={{fontWeight: 600}}*/}
          {/*          startIcon={*/}
          {/*            <SvgIcon*/}
          {/*              sx={{transform: 'scale(1.2)'}}*/}
          {/*              component={googleIcon}*/}
          {/*              viewBox="0 0 28.47 28.515"/>*/}
          {/*          }*/}
          {/*        >*/}
          {/*          {t('accountGoogleSignIn')}*/}
          {/*        </Button>*/}
          {/*      )}*/}
          {/*    />*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
        </Grid>
      </form>

      <Modal
        open={mustChangePassword}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          backgroundColor={'white'}
          maxWidth={700}
          minWidth={'20%'}
          sx={{
            position: 'absolute',
            left: { xs: '10%', md: '50%' },
            top: { xs: '25%', md: '50%' },
            transform: { xs: 'translate(-5%, -25%)', md: 'translate(-50%, -50%)' },
            borderRadius: 10
          }}
        >
          <Box sx={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center', padding: 5 }}>
            <ChangePassword
              isLogin={true}
              onSuccess={redirect}
            />
          </Box>
        </Box>
      </Modal>

    </Box>
  );
};
export default Form;
