import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from 'common/Container';
import React, {useEffect} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {AccountServices} from 'services';
import LogoB from 'svg/logos/LogoBeyaz.svg';
import LogoBlue from 'svg/logos/LogoBlue.svg';
import background from 'svg/misc/background.svg';
import Swal from 'sweetalert2';
import {Form} from './components';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

const LoginSimple = (props) => {
  const {match: {params: {lang}}} = props;
  const {t, i18n} = useTranslation();
  let history = useHistory();
  let location = useLocation();
  const query = new URLSearchParams(location.search);
  const accountverification = query.get('v');
  let accontVerificationUrl = '/account/account-verification?V=' + accountverification + '&NotMe=false';

  useEffect(() => {
    if (accountverification) {
      let promise = AccountServices.verifyAccount(accontVerificationUrl);
      promise.then((res) => {
        if (res) {
          let timerInterval;
          Swal.fire({
            icon: 'success',
            title: t('success'),
            timer: 4000,
            timerProgressBar: true,
            showCloseButton: false,
            showConfirmButton: false,
            showDenyButton: false,
            text: t('emailValidateOK'),
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              history.push('/login');
            }
          });
        }
      }).catch((e) => {
        console.log(e);
      });
    }
  }, []);

  useEffect(() => {
    // console.log('params', props);
    // console.log('lang', localStorage);
    if (lang === 'en' || lang === 'tr') {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  return (
    <Grid
      container
      minHeight={'calc(100vh)'}
      sx={{backgroundColor:'#F0F5FF'}}
    >
      <Grid
          item
          container
          alignItems={'flex-end'}
          justifyContent={'center'}
          xs={12}
          md={4}
          sx={{
            display:{xs:'none',md:'flex'},
            backgroundImage: `url(${background})`,
            position:'relative',
            backgroundRepeat: 'no-repeat',
            borderRadius:'0px 50px 50px 0px',
            backgroundPosition: {xs: 'center', md: 'center', lg: 'center', xl: 'center'},
            paddingBottom:'100px',
            '&:after': {content: '""',
              backgroundImage: 'linear-gradient(156deg, rgba(5, 21, 57, 0.32) 46.75%, rgba(5, 21, 57, 0.62) 98.99%)',
              left:0,right:0,top:0,bottom:0,
              position:'absolute',
              opacity:1,
              borderRadius: '0px 50px 50px 0px',
              transition:'all 1s ease-in-out'},
          }}
      >
       <Box sx={{position:'absolute', left:'15%', top:'60px', zIndex:'2'}}>
         <Box
             to="/"
             component={Link}
             display={'inline-block'}
             marginBottom={8}
             marginTop={{xs: 10, md: 0}}
             sx={{height: {md: '59px', xs: '40px'}, width: {md: '188px', xs: '170px'}}}>
             <img style={{maxWidth: '188px'}} src={LogoB}/>
         </Box>
       </Box>
        <Box
            display={'flex'}
            alignItems={'flex-end'}
            width={{xs: '100%', md: '100%', lg: '80%'}}
            position={'relative'}
            zIndex={2}
        >
          <Container>
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'space-between'}
            >
              <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 700,
                    color: 'white',
                    textAlign: 'left',
                    maxWidth:'270px'
                  }}
              >
                {t('loginLeftPanelTitle')}
              </Typography>

              <Box marginTop={5} display={'flex'}>
                <Typography
                    variant="p"
                    sx={{
                      fontWeight: 400,
                      color: 'white',
                      fontSize:'16px',
                      textAlign: 'left',
                      maxWidth:'350px'
                    }}

                >
                  {t('loginLeftPanelText1')}
                </Typography>
              </Box>
              {/*<Box margin={5} display={'flex'}>*/}
              {/*  <SvgIcon*/}
              {/*      htmlColor={'green'}*/}
              {/*      sx={{transform: 'scale(1.5)', marginRight: 3, marginTop: 1}}*/}
              {/*      component={Checkmark}*/}
              {/*      viewBox="0 0 31.693 22.658"/>*/}
              {/*  <Typography*/}
              {/*      variant="tex"*/}
              {/*      sx={{*/}
              {/*        fontWeight: 500,*/}
              {/*        color: 'white',*/}
              {/*        textAlign: 'left'*/}
              {/*      }}*/}
              {/*  >*/}
              {/*    {t('loginLeftPanelText3')}*/}
              {/*  </Typography>*/}
              {/*</Box>*/}
              {/*<Box*/}
              {/*    textAlign="center"*/}
              {/*>*/}
              {/*  <Button*/}
              {/*      variant="contained"*/}
              {/*      onClick={() => history.push('/signup')}*/}
              {/*      color="secondary"*/}
              {/*      size="large"*/}
              {/*      sx={{color: '#53B2FF', fontWeight: 700}}*/}
              {/*  >*/}
              {/*    {t('accountSignUp')}*/}
              {/*  </Button>*/}
              {/*</Box>*/}
            </Box>
          </Container>
        </Box>
      </Grid>
      <Grid
        item
        container
        alignItems={'center'}
        justifyContent={'center'}
        xs={12}
        md={8}
        backgroundColor="#F6F6F6">
        <Box display={'flex'} flexGrow={'1'} alignItems={'center'} height={'100%'}>
          <Container maxWidth={'440px'}>
              <Box sx={{display:{xs:'block', md:'none'}, textAlign:'center', padding:'40px 0 20px 0'}}>
                  <img style={{width: '167px'}} src={LogoBlue}/>
              </Box>
            <Form/>
          </Container>
        </Box>
      </Grid>
    </Grid>
  );
};

LoginSimple.propTypes = {
  match: PropTypes.object
};

export default LoginSimple;
