export const CHANGE_AUTHENTICATION = 'CHANGE_AUTHENTICATION';
export const SET_JWT = 'SET_JWT';
export const SET_USER = 'SET_USER';
export const SET_PRIVILEGES = 'SET_PRIVILEGES';
export const SET_APPLICATION_INFORMATION_IS_LOADED = 'SET_APPLICATION_INFORMATION_IS_LOADED';
export const SET_PRIVILEGE_NAMES = 'SET_PRIVILEGE_NAMES';
export const SET_CONTAINER_LOADER = 'SET_CONTAINER_LOADER';
export const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT';
export const SET_SELECTED_PACKAGE = 'SET_SELECTED_PACKAGE';
export const SET_PACKAGES = 'SET_PACKAGES';
export const SET_IS_LOGIN = 'SET_IS_LOGIN';
export const SET_CALENDAR_DATE = 'SET_CALENDAR_DATE';
export const SET_IS_CURRENCY_DOLLAR = 'SET_IS_CURRENCY_DOLLAR';
export const LOG_OUT = 'LOG_OUT';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const SET_HUB_CONNECTION = 'SET_HUB_CONNECTION';
export const SET_INCOMING_CALL = 'SET_INCOMING_CALL';
export const SET_OUTGOING_CALL = 'SET_OUTGOING_CALL';
export const SET_ONLINE_USERS = 'SET_ONLINE_USERS';
export const SET_OUT_GOING_USER_NAME = 'SET_OUT_GOING_USER_NAME';
