import {Ajax} from '../utils/index';

export default {

  getUserInformation() {
    let promise = Ajax.getPromise('/Session/get-current-user-information');
    return promise;
  },
  getUserProfileInformation() {
    let promise = Ajax.getPromise('/user/get-user-profile-information');
    return promise;
  },
  editUser(data) {
    let promise = Ajax.postPromise('/user/edit-user', data);
    return promise;
  },
  changePassword(data) {
    let promise = Ajax.postPromise('/user/change-password', data);
    return promise;
  },
  getAllCompanyUsers() {
    let promise = Ajax.getPromise('/user/get-all-company-users');
    return promise;
  }
};
