import {Avatar, Box, List, ListItem} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useCookies} from 'react-cookie';
import {useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import handleSignOut from 'utils/signout';
import {useTranslation} from 'react-i18next';

const UserDropdown = ({user}) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [cookie, setCookie, removeCookie] = useCookies(['loginToken']);
  // const hubConnection = useSelector((state) => state.main.hubConnection);

  const profileRoutes = [
    {id: 1, title: t('headerProfile'), href: '/profile/profile'},
    {id: 2, title: t('meetings'), href: '/profile/meetings'},
    {id: 3, title: t('headerSignOut'), href: '#'}
  ];

  const disconnectHubAndSignOut = () => {
    // hubConnection.stop();
    handleSignOut(dispatch, removeCookie, history);
  };

  const options = profileRoutes.map((option, i) => {
    return <ListItem
      sx={{
        paddingLeft: '15px',
        paddingRight: '35px',
        transition: 'all .3s linear',
        fontWeight: 400,
          paddingTop:'3px',
          paddingBottom:'3px',
        color: '#53B2FF',
        '&:focus': {
          backgroundColor: '#F0F5FF',
        },
        '&:hover': {
          backgroundColor: '#F0F5FF',
        },
        '&:first-of-type': {
          borderRadius: '0',
        },
        '&:last-of-type': {
          borderRadius: '0 0 10px 10px',
        }
      }}
      component={Link}
      onClick={option.id === 3 ? () => disconnectHubAndSignOut() : null}
      to={option.href} key={i}>
      {option.title}
    </ListItem>;
  });

  return (
    <Box sx={{
      position: 'relative',
      cursor: 'pointer',
      '& .MuiBox-root.MuiBox-root': {
        display: 'flex',
        alignItems: 'center'
      },
     '& .MuiList-root': {
            transition:'all 1s ease-in-out'
      },
      '&:hover': {
        '& .MuiList-root': {
          opacity: 1,

            visibility:'visible'
        },
      }

    }} >

      <Box
        display={'flex'}
        minWidth={185}
        padding={'8px'}
        className='targetBox'
        sx={{
          border: '2px solid #F0F5FF',
          borderRadius: '10px !important',
          fontSize: '0.875rem',
          backgroundColor: '#F0F5FF',
          direction: i18n.language === 'ar' ? 'initial' : null
        }}
      >
        <Box sx={{
          borderRadius: '5px !important',
        }}>
            <Avatar src="/broken-image.jpg" sx={{height: 35, width: 35, borderRadius: '10px'}}/>
        </Box>
        <Box paddingLeft={'10px'} flex={1} overflow={'hidden'} sx={{
          position: 'relative', '&:after': {
            content: '""',
            position: 'absolute',
            width: '7px',
            height: '7px',
            right: '16px',
            top: '11px',
            zIndex: 1,
            borderRight: '2px solid #53B2FF',
            borderBottom: '2px solid #53B2FF',
            transform: 'rotate(45deg)'
          }
        }}>
          <div style={{
            color: '#53B2FF',
            position: 'relative',
            fontWeight: 500,
          }}>
            <div style={{lineHeight: '0.875rem', marginBottom: '2px'}}>
              {user.name}
            </div>
            <div style={{lineHeight: '0.875rem'}}>
              {user.surname}
            </div>
          </div>
        </Box>
      </Box>
      <List sx={{
        top: '50px !important',
        border: '2px solid #F0F5FF',
        borderRadius: '0 0 10px 10px',
        fontSize: '0.875rem',
        backgroundColor: '#F0F5FF',
        overflow: 'hidden',
        position: 'absolute',
        opacity: 0,
          visibility:'hidden',
        margin: '0',
        padding: '10px 0 10px 0',
        listStyle: 'none',
        width: 185
      }}>
        {options}
      </List>
    </Box>
  );

};

UserDropdown.propTypes = {
  user: PropTypes.object,
};

export default UserDropdown;
